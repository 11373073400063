@import 'common/Common';

.container {
    height: 100%;
    margin-bottom: 24px;
    border: 1px solid #cccccc40;
    border-radius: 8px;
    padding: 40px;

    .title-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 40px;
    }

    .image-container {
        display: flex;
        justify-content: center;
        margin-bottom: 40px;
    }

    .answers-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
