@import 'common/Common';

.spin-container {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 50px;
}

.container {
    min-height: calc(100vh - #{$navigation-bar-height});
}
