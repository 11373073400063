@import 'common/Common.scss';

.container {
    background-color: #f5f5f5;
    min-height: 296px;
    padding: 0 40px 40px 40px;
    width: 100%;

    .text {
        width: 100%;
        font-family: Roboto, sans-serif;
        font-size: 24px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.5px;
        color: $lively-blue-500;
        text-align: center;
        padding: 32px 0;
    }

    .school-logos-container {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 35px;
    }
}
