@import 'common/Common.scss';

.container {
    :global {
        .ant-modal-content {
            border-radius: 8px;
        }
    }

    .body {
        display: flex;
        flex-direction: column;
        padding: 0 25px;

        .title {
            width: 100%;
            margin-bottom: 16px;
        }

        .promo-code-container {
            margin: 12px 0 16px 0;
        }

        .button-container {
            width: 100%;
            display: flex;
            justify-content: flex-end;

            .link-button {
                padding: 0;

                :global {
                    .anticon {
                        margin-left: 4px;
                    }
                }
            }
        }
    }

    .footer {
        button {
            margin-left: 16px;
        }
    }
}
