@import 'common/Common';

.container {
    width: 338px;
    height: 400px;
    border: 1px solid $grey-100;
    border-radius: 8px;
    cursor: pointer;

    .text-content {
        padding: 32px 24px;

        .title {
            color: $lively-blue-500;
            font-family: Poppins, sans-serif;
            font-size: 18px;
            line-height: 27px;
            font-weight: 500;
            margin-bottom: 8px;
        }

        .description {
            color: $dark;
        }
    }

    &:hover {
        box-shadow: 0 3px 25px 0 #cccccc40;
    }
}
