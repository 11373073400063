@import 'common/Common.scss';

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 20px;

    .header {
        text-align: center;
        width: 100%;
        margin: 64px 0 40px 0;
    }

    .list-container {
        display: flex;
        justify-content: center;
        width: 100%;

        :global {
            .ant-list {
                width: 70%;
                display: flex;
                justify-content: center;

                .ant-spin-nested-loading {
                    width: 100%;
                }

                @media only screen and (max-width: 768px) {
                    width: 100%;
                    margin: 0 24px;
                }
            }
        }

        .list-item-container {
            min-height: 75px;
            width: 100%;
            margin-bottom: 24px;
            border: 1px solid $grey-100;
            border-radius: 8px;
            background-color: white;

            // .ant-list-item sets border-bottom to none by default, this exists to override it
            &:last-child {
                border-bottom: 1px solid $grey-100;
            }

            &:hover {
                cursor: pointer;
                box-shadow: 0 3px 25px 0 #cccccc40;
            }

            .list-item-text {
                display: block;
                padding: 0 40px;
                color: $dark;
                margin: 0;
            }
        }
    }
}
