@import 'common/Common.scss';

.container {
    width: 100%;
    margin-bottom: 24px;

    .cohort-display {
        .countdown-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            gap: 20px;
            margin-bottom: 16px;

            .cohort-display {
                text-align: right;

                .subtitle {
                    color: $lively-blue-500;
                    margin-bottom: 4px;
                }

                @media only screen and (max-width: 480px) {
                    text-align: left;
                }
            }
        }

        .video-title {
            color: $grey-500;

            .video-title-name {
                font-style: italic;
                color: black;
            }
        }
    }

    .no-cohort-display {
        position: relative;

        .image-container {
            height: 100%;
            margin-bottom: 24px;
        }

        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            background-color: rgba(255, 255, 255, 0.9);
            width: 100%;
            height: 100%;
            padding: 12px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .content-container {
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
                width: 40%;

                @media only screen and (max-width: 768px) {
                    width: 100%;
                }

                .header {
                    margin-bottom: 24px;
                    color: $lively-blue-500;
                    font-size: 24px;
                    line-height: 30px;

                    @media only screen and (max-width: 768px) {
                        font-size: 20px;
                    }
                }

                .cta-button {
                    font-size: 20px;
                    height: 50px;
                    border-radius: 25px;
                    padding: 0 20px;

                    @media only screen and (max-width: 768px) {
                        font-size: 16px;
                        height: 40px;
                        border-radius: 20px;
                    }
                }
            }
        }
    }

    .action-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 36px;

        .buttons-container {
            display: flex;
            flex-wrap: wrap;
            gap: 12px;
            margin-bottom: 8px;

            button {
                margin-right: 24px;
            }
        }

        .reminder {
            color: $arc-bright-red;
        }
    }
}

.loading-container {
    height: 100%;
    display: flex;
    justify-content: center;
    padding-top: 60px;
}
