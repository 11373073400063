@import 'common/Common.scss';

.container {
    .list-container {
        width: 100%;
        min-height: 400px;

        .header {
            font-weight: 500;
            color: $dark;
        }

        .row {
            min-height: 40px;
            padding: 10px 20px;
            margin-top: 4px;
        }

        .highlight {
            font-weight: 500;
            background-color: #fdf398;
            font-size: 16px;
            border-radius: 8px;
        }
    }
}
