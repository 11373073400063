$min-notes-panel-width: 475px;

.panel-width-grow {
    animation: width-grow-animation 0.2s forwards ease-in;
}

@keyframes width-grow-animation {
    0% {
        min-width: 0;
    }
    100% {
        min-width: $min-notes-panel-width;
    }
}
