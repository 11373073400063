@import 'common/Common';

.container {
    width: 450px;
    height: 500px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
    background-color: white;

    @media only screen and (max-width: 480px) {
        width: 100%;
    }
}
