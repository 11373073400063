@import 'common/Common';

.container {
    .title {
        margin: 64px 0;
    }

    .buttons-footer {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 48px;

        > button {
            height: 40px;
            margin-left: 16px;
            border-radius: 4px;
        }
        .back-button {
            color: $lively-blue-500;
            border-color: $lively-blue-500;

            &:hover {
                color: $lively-blue-400;
                border-color: $lively-blue-400;
            }

            &:disabled {
                color: $grey-200;
                border-color: $grey-200;
            }
        }

        .submit-button {
            background-color: $lively-blue-500;
            border: none;

            &:hover {
                background-color: $lively-blue-400;
            }

            &:disabled {
                color: $grey-200;
                border: 1px solid $grey-200;
                background-color: #f5f5f5;
            }
        }
    }
}
