@import 'common/Common';

.container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .header {
        margin: 64px 0 64px 0;
        color: $dark;

        @media only screen and (max-width: 768px) {
            margin: 32px 0 32px 0;
            font-size: 22px;
        }
    }

    .active-tabs-container {
        margin: 0 auto 64px auto;
    }
}

.spin-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    padding-top: 200px;
}
