@import 'common/Common.scss';

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .content-container {
        width: 100vw;
        padding: 20px 30px;

        .score-card {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: $lively-blue-500;
            color: white;
            height: 60px;
            padding: 0 20px;
            border-radius: 12px;
            overflow: visible;
            margin: 20px 0 40px 0;
            font-weight: 500;

            .timer {
                background-color: white;
                color: $dark;
                width: 90px;
                height: 90px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 45px;
                font-size: 36px;
                font-weight: 500;
                border: 2px solid $lively-blue-200;
            }

            .danger {
                color: red;
            }
        }
    }

    @media (min-width: 600px) {
        .content-container {
            width: 60%;
        }
    }
}
