@import 'common/Common';

.container {
    flex-grow: 1;
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    .highlight {
        color: $lively-blue-500;
        font-weight: bold;
    }

    .strike {
        text-decoration: line-through;
    }

    .check-outlined {
        color: $lively-blue-500;
        margin-right: 15px;

        &.is-disabled {
            color: $grey-500;
        }
    }
}
