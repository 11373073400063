$arc-blue: #7399c6;
$arc-medium-blue: #163760;
$arc-dark-blue: #000d1e;
$url-hover-blue: #40a9ff;

$arc-bg-grey: #eeeeee;

$arc-yellow: #ffdc46;

$arc-red: #8c1911;
$arc-bright-red: #cf2a27;

$navigation-bar-height: 97px; // 96px + 1px to account for border-bottom and remove scrollbar
$footer-height: 184px;

$lively-blue-500: #185adb;
$lively-blue-400: #2c6ce8;
$lively-blue-200: #a5c0f5;
$lively-blue-100: #e1eafc;

$dark: #07193d;

$grey-500: #6e7276;
$grey-400: #8a8f93;
$grey-300: #a8abae;
$grey-200: #c5c8ca;
$grey-100: #eaebec;

$messages-grey: #969696;

$error-red: #c0302e;

$default-box-shadow: 0 3px 25px 0 #cccccc40;

@mixin public-header-1 {
    font-family: Poppins, sans-serif;
    font-size: 50px;
    font-weight: 500;
    line-height: 60px;
    letter-spacing: 0;
    color: $dark;

    @media only screen and (max-width: 480px) {
        font-size: 40px;
        line-height: 50px;
    }
}

@mixin public-header-2 {
    font-family: Poppins, sans-serif;
    font-size: 30px;
    font-weight: 500;
    line-height: 42px;
    letter-spacing: 0;
    color: $dark;
}

@mixin public-body-text-1 {
    font-family: Roboto, sans-serif;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: $dark;
}

@mixin public-subtitle-1 {
    font-family: Roboto, sans-serif;
    font-size: 24px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: $dark;
}

@mixin public-body-text-2 {
    font-family: Roboto, sans-serif;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: $dark;
}

@mixin public-section-title {
    @include public-header-2;
    padding: 0 20px;
    width: 100%;
    text-align: center;
    margin: 32px 0;
}

// as DraftJS WYSIWYG use <img> to render svg, we can only change the color by using filter like so
@mixin img-filter-grey-500 {
    filter: invert(48%) sepia(5%) saturate(355%) hue-rotate(169deg) brightness(90%) contrast(88%);
}

@mixin img-filter-lively-blue-400 {
    filter: invert(45%) sepia(50%) saturate(7500%) hue-rotate(211deg) brightness(96%) contrast(88%);
}

@mixin img-filter-dark {
    filter: invert(12%) sepia(23%) saturate(2673%) hue-rotate(188deg) brightness(93%) contrast(105%);
}

@mixin img-filter-lively-blue-500 {
    filter: invert(24%) sepia(97%) saturate(3331%) hue-rotate(218deg) brightness(90%) contrast(90%);
}
