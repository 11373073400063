@import 'common/Common.scss';

.container {
    width: 100%;
    min-height: 270px;
    border: 1px solid $grey-200;
    border-radius: 16px;

    display: flex;
    overflow: hidden;

    @media (max-width: 1080px) {
        width: 360px;
        flex-direction: column;

        .thumbnail-container {
            width: 100%;
            display: flex;
            justify-content: center;
        }
    }

    &:hover {
        box-shadow: 0 3px 25px 0 #cccccc40;
    }

    .thumbnail-container {
        flex-shrink: 0;

        img {
            object-fit: cover;
        }
    }

    .content-container {
        padding: 20px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;

        .title-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            .title {
                margin-bottom: 8px;
            }

            .price {
                text-align: right;
                font-size: 30px;
                font-weight: 500;
            }
        }

        .description {
            flex-grow: 1;
            width: 100%;
            margin-bottom: 24px;
        }

        .buttons-container {
            display: flex;
            justify-content: flex-end;
            width: 100%;
            height: 100%;
            flex-wrap: wrap;
            gap: 16px;
            align-items: flex-end;
        }
    }
}
