@import 'common/Common';

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 0 24px;

    @media only screen and (min-width: 768px) {
        width: 700px;
    }

    .spin-container {
        width: 100%;
        padding-top: 50px;
        display: flex;
        justify-content: center;
    }

    .content-container {
        width: 100%;
        margin-bottom: 48px;

        .progress-indicator-container {
            margin-bottom: 24px;
        }

        .title {
            display: flex;
            justify-content: center;
            text-align: center;
            margin: 32px 0 32px 0;
            padding: 0 12px;
        }

        .subtitle {
            margin-bottom: 24px;
        }

        .image-container {
            display: flex;
            justify-content: center;
            margin-bottom: 48px;
            width: 100%;

            @media only screen and (min-width: 480px) {
                width: 500px;
            }
        }

        .buttons-footer {
            display: flex;
            justify-content: flex-end;
            width: 100%;
        }
    }
}
