@import 'common/Common';

.download,
.go-back,
.back-to-top,
.zoom-in,
.zoom-out,
.thumbs-up,
.thumbs-down,
.write-notes,
.print-notes,
.this-is-hard,
.get-study-plan {
    padding: 10px;
    cursor: pointer;

    color: $grey-500;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.thumbs-up,
.thumbs-down {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    user-select: none;
}

.text {
    font-size: 12px;
    margin-top: 4px;
    user-select: none;
    text-align: center;

    @media (max-width: 450px) {
        display: none;
    }
}

.active-text {
    color: $lively-blue-400;
}

// to override svg path colors of two tone icons
.thumbs-active {
    svg {
        path:nth-child(2) {
            fill: $lively-blue-400;
        }
    }
}

.notes-active {
    svg {
        color: $lively-blue-400;
    }
}

.this-is-hard-active {
    svg {
        path:nth-child(1) {
            fill: $lively-blue-400;
        }

        path:nth-child(3) {
            fill: $lively-blue-400;
        }
    }
}
