@import 'common/Common';

.container {
    :global {
        .ant-btn {
            height: 56px;
            min-width: 219px;
            border-radius: 28px;
            padding: 16px 32px;

            font-family: Roboto, sans-serif;
            font-size: 24px;
            font-weight: 500;
            line-height: 24px;
            color: white;
            background-color: $lively-blue-500;
            border: none;

            &:hover {
                background-color: $lively-blue-400;
                color: white;
            }
        }

        .anticon-arrow-right {
            font-size: 20px;

            svg {
                height: 22px; // to align the arrow with the text
            }
        }
    }
}
