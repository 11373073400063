@import 'common/Common';

.spin-container {
    padding-top: 50px;
    display: flex;
    justify-content: center;
}

.container {
    padding: 24px;

    .title-container {
        text-align: center;
        padding-bottom: 25px;
    }

    .active-tabs-container {
        width: 70%;
        margin: 25px auto;
    }

    .list-container {
        width: 70%;
        margin: 25px auto;

        .list-item-container {
            border: 1px solid $grey-200;
            border-bottom: none;
            padding: 0;
            align-items: stretch;

            &:last-child {
                border-bottom: 1px solid $grey-200;
            }

            .list-item-link {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                width: 100%;
                padding: 15px 35px;
                color: black;
                font-size: 16px;

                &:hover {
                    color: white;
                    background-color: $arc-blue;
                    cursor: pointer;
                }

                .file-name {
                    flex-grow: 1;
                }
            }

            .list-icon {
                width: 50px;
                display: flex;
                justify-content: center;
                align-items: center;

                &:hover {
                    background-color: $arc-blue;
                    color: white;
                    cursor: pointer;
                }
            }
        }
    }
}
