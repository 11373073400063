@import 'common/Common';

@mixin info-container {
    display: flex;
    justify-content: center;
    padding-top: 50px;
}

.error-container {
    @include info-container;

    & > h4 {
        text-align: center;
    }
}

.loading-container {
    @include info-container;
    overflow-y: hidden;
    width: 1080px;
    height: 500px;
}

.container {
    .zoom-controls {
        position: fixed;
        top: 50px;
        right: 50px;
        z-index: 10;
        border: 1px solid $grey-100;
        padding: 15px 25px;
        display: flex;
        justify-content: space-between;
        width: 150px;
        background-color: white;
    }

    .control-container {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        justify-content: center;
    }
}
