@import 'common/Common';

.container {
    display: flex;

    border-radius: 4px;
    padding: 16px;
    width: 100%;

    .icon-container {
        display: flex;
        justify-content: center;
        margin-right: 16px;
        margin-top: 2px; // to align the icon with the text

        svg {
            font-size: 20px;
        }
    }

    .text-container {
        margin: 0;
    }
}

.info {
    border: 1px solid $lively-blue-500;
    color: $lively-blue-500;

    svg {
        color: $lively-blue-500;
    }

    .text-container {
        color: $lively-blue-500;
    }
}

.error {
    border: 1px solid $error-red;
    svg {
        color: $error-red;
    }

    .text-container {
        color: $error-red;
    }
}
