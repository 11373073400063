@import 'common/Common';

.container {
    padding: 25px;

    .no-videos {
        font-size: 20px;
        font-weight: 500;

        .cta {
            margin-top: 15px;
            margin-left: 25px;
        }
    }

    .spin-container {
        display: flex;
        justify-content: center;
        margin-top: 50px;
    }

    .videos-container {
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
    }
}
