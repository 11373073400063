@import 'common/Common';

.container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .header {
        margin: 64px 0 48px 0;
        color: $dark;

        @media only screen and (max-width: 768px) {
            margin: 32px 0 32px 0;
            font-size: 22px;
        }
    }

    .list-container {
        display: flex;
        justify-content: center;

        .list-item-container {
            height: 75px;
            min-width: 550px;
            margin-bottom: 24px;
            border: 1px solid $grey-100;
            border-radius: 8px;

            @media only screen and (max-width: 768px) {
                min-width: 350px;
            }

            // .ant-list-item sets border-bottom to none by default, this exists to override it
            &:last-child {
                border-bottom: 1px solid $grey-100;
            }

            &:hover {
                cursor: pointer;
                box-shadow: 0 3px 25px 0 #cccccc40;
            }

            .list-item-text {
                display: block;
                padding: 0 40px;
                color: $dark;
                margin: 0;
            }
        }
    }
}

.spin-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    padding-top: 200px;
}
