@import 'common/Common.scss';

.spin-container {
    padding-top: 100px;
    display: flex;
    justify-content: center;
}

.error-container {
    padding-top: 150px;
    text-align: center;
}

.no-content-container {
    padding: 100px;

    .button-container {
        margin-top: 30px;
    }
}

.container {
    min-height: calc(100vh - #{$navigation-bar-height});

    .breadcrumbs {
        margin-bottom: 24px;

        .bc-item {
            &:hover {
                cursor: pointer;
                color: $url-hover-blue;
            }
        }
    }
}
