@import 'common/Common.scss';

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .content-container {
        width: 100vw;
        padding: 20px 30px;
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .title {
            margin-bottom: 12px;
        }

        .cta {
            text-align: center;
            background-color: $arc-blue;
            color: white;
            min-height: 100px;
            display: flex;
            justify-content: center;
            align-items: center;
            max-width: 500px;
            border-radius: 12px;
            margin-bottom: 32px;
            padding: 16px;
            font-size: 20px;
        }

        .condensed-notes-container {
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
        }

        .explanation-notes-container {
            width: 100%;
            margin-bottom: 32px;
        }

        .the-question-container {
            width: 100%;

            .header {
                margin-bottom: 16px;
                text-align: center;
                width: 100%;
            }
        }

        .video-container {
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .buttons-container {
            margin: 20px 0;
            display: flex;
            flex-direction: column;

            button {
                margin-bottom: 12px;
                width: 100%;
            }

            .play-again {
                color: $arc-blue;
                border: 1px solid $arc-blue;
                background-color: white;

                &:hover {
                    background-color: lighten($arc-blue, 5%);
                    color: white;
                }
            }
        }
    }

    @media (min-width: 768px) {
        .content-container {
            width: 50%;
        }
    }
}
