@import 'common/Common';

.tab-pane {
    color: $dark;
    margin-right: 32px;

    > div {
        //to ensure width stays the same when it toggles between normal and bold
        &:before {
            display: block;
            content: attr(data-title);
            font-weight: bold;
            height: 0;
            overflow: hidden;
            visibility: hidden;
        }
    }

    &:hover {
        color: $lively-blue-400;
        cursor: pointer;
    }

    &.tab-pane-active {
        color: $lively-blue-500;
        padding-bottom: 8px;
        border-bottom: 2px solid $lively-blue-500;
    }
}
