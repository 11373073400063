@import 'common/Common';
@import '../../Common';

.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    .title-bar {
        @include title-bar;

        .minus {
            position: absolute;
            left: 12px;
            top: 10px;
            cursor: pointer;

            svg {
                color: white;
            }
        }
    }

    .threads {
        height: 450px;
        padding: 20px 26px;
        overflow-y: auto;
        flex-grow: 1;

        .placeholder {
            display: flex;
            flex-direction: column;
            align-items: center;

            // to offset white border on the right and bottom original svg
            .image-container {
                margin-left: 24px;
            }

            .text-container {
                color: $grey-500;
                font-style: italic;
                text-align: center;
                margin-top: 8px;
            }
        }
    }

    .new-question-button {
        border: none;
        color: white;
        border-radius: 0;
        background-color: $lively-blue-500;
        height: 40px;
        font-weight: 500;
        flex-shrink: 0;

        &:hover {
            background-color: $lively-blue-400;
            color: white;
        }
    }
}

.loading-container {
    height: 100%;
    display: flex;
    justify-content: center;
    padding-top: 80px;
}

.error-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 120px;
    font-size: 16px;
    text-align: center;

    .retry {
        background-color: $lively-blue-500;
        color: white;
        font-weight: 500;
        border-radius: 4px;
        border: none;
        margin: 8px;

        &:hover {
            background-color: $lively-blue-400;
        }
    }
}
