@import 'common/Common.scss';

@mixin contentStyle {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

@mixin subjectImg {
    object-fit: cover;
}

@mixin subjectTitle {
    margin: auto 16px;
    text-align: center;
    color: $dark;

    .highlight {
        color: #eb6d6a;
    }
}

@mixin subjectStyle {
    text-align: center;
    border-radius: 10px;
    border: 1px solid $grey-100;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin: 12px;
    background-color: white;

    &:hover {
        cursor: pointer;
        box-shadow: 0 3px 25px 0 #cccccc40;
    }
}

.container {
    .header {
        margin: 64px 0 28px 0;
        text-align: center;
    }
    .core-content {
        @include contentStyle;

        margin-bottom: 50px;

        .subject {
            @include subjectImg;
            @include subjectTitle;
            @include subjectStyle;

            min-width: 600px;
            height: 500px;

            .subtitle {
                font-size: 36px;
            }
        }
    }

    .other-content {
        @include contentStyle;

        .subject {
            @include subjectImg;
            @include subjectTitle;
            @include subjectStyle;

            min-width: 240px;
            height: 235px;

            .subtitle {
                font-size: 18px;
            }
        }
    }
}
