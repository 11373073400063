@import 'common/Common';

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 100px;

    .section-title {
        @include public-section-title;
        margin: 64px 0 32px 0;
    }

    .slider-container {
        width: 70%;

        :global {
            .slick-slider {
                // styling for the arrows
                .slick-prev:before,
                .slick-next:before {
                    color: $lively-blue-500;
                }

                .slick-dots {
                    button:before {
                        color: $lively-blue-500;
                    }
                }

                // there is an element.style overriding the css so !important is needed
                .item-container {
                    display: flex !important;
                    justify-content: center;

                    .testimonial-container {
                        min-height: 460px;

                        flex-direction: column;
                        align-items: center;
                        width: 90%;

                        padding: 40px 24px;
                        margin: 20px 0;
                        background-color: #ffffff;
                        box-shadow: 1px 1px 28px #efefef;
                        border-radius: 8px;

                        .image-container {
                            width: 100%;
                            text-align: center;
                            margin-bottom: 10px;
                        }

                        > h3 {
                            @include public-subtitle-1;
                            text-align: center;
                            font-size: 22px;
                            margin-bottom: 24px;
                        }

                        .testimonial {
                            @include public-body-text-2;
                            text-align: center;
                        }
                    }
                }
            }
        }
    }
}
