@import 'common/Common.scss';

.container {
    min-height: calc(100vh - #{$navigation-bar-height} + 2px);
    display: flex;
    justify-content: center;

    @media (min-width: 480px) {
        .content-container {
            width: 70%;
        }
    }
    .content-container {
        .title {
            display: flex;
            justify-content: center;
            text-align: center;
            margin: 20px 0 12px 0;
            padding: 0 12px;
        }

        .content {
            font-family: Roboto, sans-serif;
            text-align: center;
            font-size: 20px;
            padding: 0 20px;
            margin-bottom: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;

            .text {
                margin-bottom: 20px;
            }

            .image-container {
                justify-content: center;
                width: 80%;
                margin-bottom: 20px;

                @media (min-width: 480px) {
                    width: 300px;
                }
            }

            .buttons-container {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 8px;
                .back {
                    font-weight: 400;
                }
            }
        }
    }
}

.loading-container {
    padding-top: 150px;
    display: flex;
    justify-content: center;
}
