@import 'common/Common.scss';

.container {
    min-height: calc(100vh - #{$navigation-bar-height} + 2px);
    padding: 20px;
    width: 100%;

    .content-container {
        display: flex;
        flex-direction: column;

        .tag-container {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            gap: 8px;
            flex-wrap: wrap;

            .subject-tag {
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 4px;
                width: 120px;
                height: 30px;
                user-select: none;
            }

            .show-leaderboard {
                font-weight: 400;
            }
        }

        .button-container {
            width: 100%;
            display: inline-flex;
            flex-direction: column;
            align-items: center;

            .start-container {
                width: 100%;

                .start {
                    width: 100%;
                    margin-bottom: 10px;
                }
            }

            .back {
                margin-bottom: 20px;
                font-weight: 400;
            }
        }

        .title-container {
            display: flex;
            align-items: center;

            .title {
                color: $dark;
                font-weight: 500;
                margin-right: 12px;
            }
        }

        .subtitle {
            margin-bottom: 12px;
        }

        .option {
            cursor: pointer;
            display: flex;
            user-select: none;

            :global {
                .ant-checkbox-wrapper {
                    margin-right: 8px;

                    .ant-checkbox-inner {
                        scale: 1.2;
                        border-radius: 4px;
                    }

                    // override the default border color when checkbox is checked and hovered
                    .ant-checkbox::after {
                        border-color: $lively-blue-500;
                    }

                    .ant-checkbox-checked {
                        .ant-checkbox-inner {
                            background-color: $lively-blue-500;
                            border-color: $lively-blue-500;
                        }
                    }
                }
            }
        }

        .option-container {
            margin-bottom: 24px;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            .row {
                width: 400px;
            }

            .option {
                margin-bottom: 8px;
            }
        }

        .current-player-score {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border: 1px solid $grey-100;
            border-radius: 8px;
            background-color: white;
            box-shadow: 0 3px 25px 0 #cccccc40;
            color: $dark;
            font-weight: 500;

            > div {
                padding: 20px;
                text-align: center;
            }

            a {
                color: $lively-blue-500;

                &:hover {
                    color: $lively-blue-400;
                }
            }
        }
    }

    @media (min-width: 600px) {
        width: 60%;
    }
}
