@import 'common/Common';

.container {
    .month-row {
        display: flex;
        align-items: center;
        margin-bottom: 16px;

        .label {
            margin-right: 20px;
        }

        .option {
            margin-right: 16px;
            width: 120px;
            display: flex;
            justify-content: center;
            border: 1px solid $grey-200;
            border-radius: 4px;

            padding: 0 24px;
            height: 40px;
            box-sizing: border-box;
            align-items: center;
            cursor: pointer;
            user-select: none;

            &:hover {
                box-shadow: 0 3px 25px 0 #cccccc40;
            }
        }

        .selected {
            background-color: $lively-blue-100;
            color: $lively-blue-500;
            border: 1px solid $lively-blue-500;
        }
    }

    .year-row {
        :global {
            .ant-picker {
                width: 255px;
                height: 40px;
                border-radius: 4px;
                padding: 4px 16px;

                ::placeholder {
                    font-size: 16px;
                }
            }
        }

        display: flex;
        align-items: center;

        .label {
            margin-right: 35px;
        }
    }
}
