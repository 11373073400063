@import 'common/Common';

.container {
    :global {
        .ant-modal-content {
            border-radius: 8px;
        }
    }

    .content {
        padding: 16px;

        .header {
            margin-bottom: 16px;
        }
    }

    .footer {
        button {
            margin-left: 12px;
        }
    }
}
