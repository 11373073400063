@import 'common/Common';

.container {
    border: 1px solid $grey-200;
    min-height: 75px;
    margin-bottom: 12px;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    padding: 12px;
    cursor: pointer;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    gap: 12px;

    &:hover {
        box-shadow: $default-box-shadow;
    }

    .info {
        margin-right: 8px;

        .title {
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 250px;
            white-space: nowrap;
        }

        .created-at {
            color: $grey-400;
            font-size: 14px;
        }
    }

    .tag {
        width: 80px;
        height: 25px;
        border-radius: 4px;
        text-align: center;
    }
}
