.chat-bubble {
    width: 70%;
    border: 1px solid black;
    border-radius: 5px;
    margin-bottom: 25px;
    padding: 15px;

    &.bot {
        margin-left: auto;
        background-color: lightcyan;
    }

    .chat-role {
        margin-top: 25px;
        font-size: 12px;
        color: grey;
        font-style: italic;
    }
}

.loading-container {
    padding: 50px;

    .spin-container {
        display: flex;
        justify-content: center;
        margin-bottom: 25px;
    }

    .loading-text {
        text-align: center;
        font-size: 24px;
        font-weight: 500;
    }
}

.container {
    .empty-conv-container {
        padding: 50px;
        margin-bottom: 50px;

        .text {
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 25px;
        }
    }

    .alert-container {
        margin-bottom: 25px;
    }

    .input-container {
        .input-submit {
            // 22px is height of input count
            height: calc(100% - 22px);
        }
    }
}
