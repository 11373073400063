@import 'common/Common';

.container {
    height: 104px;
    width: 897px;
    margin-bottom: 24px;
    // .ant-list-item sets border-bottom to none by default, !important exists to override it
    border: 1px solid $grey-100 !important;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 24px 0 !important; // override default .ant-list-item padding

    .name-row {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        padding: 0 40px;
        margin-bottom: 8px;

        .text {
            color: $dark;
            margin: 0;
        }

        .score {
            color: $lively-blue-500;
            font-weight: 700;
            font-size: 16px;
            font-family: Roboto, sans-serif;
        }
    }

    .attempted-date {
        color: $grey-500;
        width: 100%;
        padding: 0 40px;
    }

    &:hover {
        cursor: pointer;
        box-shadow: 0 3px 25px 0 #cccccc40;
    }
}
