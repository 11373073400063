@import 'common/Common.scss';

.container {
    display: flex;
    justify-content: center;
    min-height: calc(
        100vh - #{$navigation-bar-height} + 2px
    ); //2px to account for navigation bar border thickness

    .body {
        width: 70%;
        margin-bottom: 72px;

        .header {
            margin: 64px 0 32px 0;
            display: flex;
            justify-content: center;
        }

        .selector {
            margin-bottom: 24px;

            button {
                margin-right: 20px;
            }
        }
    }

    @media (max-width: 1080px) {
        .body {
            width: 90%;
        }
    }
}
