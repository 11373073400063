@import 'common/Common';

.container {
    height: 75px;
    min-width: 550px;
    margin-bottom: 24px;
    // .ant-list-item sets border-bottom to none by default, !important exists to override it
    border: 1px solid $grey-100 !important;
    border-radius: 8px;

    @media only screen and (max-width: 768px) {
        min-width: 350px;
    }

    .text {
        padding: 0 40px;
        color: $dark;
        margin: 0;
    }

    .check-container {
        display: flex;
        margin-right: 40px;

        .dashed-circle {
            border: 1px dashed #d3d5d6;
            border-radius: 10px;
            width: 20px;
            height: 20px;
        }
    }

    &:hover {
        cursor: pointer;
        box-shadow: 0 3px 25px 0 #cccccc40;
    }
}
