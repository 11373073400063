@import 'common/Common';

.container {
    position: fixed;
    top: 120px;
    left: 5px;
    width: 88px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 30px 0;
    background-color: white;
    border: 1px solid $grey-100;
    box-shadow: 2px 2px 5px 2px $grey-100;
    border-radius: 10px;

    .tooltip {
        margin: 5px 5px;
    }
}

// make it a square when there is only one icon
// offset the position so the single icon remain in the same position with or without the padding
.single-icon {
    padding: 0;
    top: 150px;
}

@media (max-width: 768px) {
    .container {
        flex-direction: row;
        width: 100%;
        padding: 0;
        border-radius: 0;
        left: 0;
        bottom: 0;
        top: auto;
        overflow-x: scroll;

        &::-webkit-scrollbar {
            display: none; /* Safari and Chrome */
        }
    }

    .single-icon {
        top: auto;
    }
}
