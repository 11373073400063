@import 'common/Common.scss';

.loading {
    display: flex;
    justify-content: center;
    margin-top: 50px;
}

.container {
    min-height: calc(100vh - #{$navigation-bar-height});

    .breadcrumbs {
        margin-bottom: 24px;

        .bc-item {
            &:hover {
                cursor: pointer;
                color: $url-hover-blue;
            }
        }
    }
}
