@import 'common/Common';

.container {
    position: relative;

    .loading-overlay {
        position: absolute;
        z-index: 1;

        // to account for ant design's modal of padding 24px
        top: -24px;
        left: -24px;
        height: calc(100% + 48px);
        width: calc(100% + 48px);

        background-color: black;
        opacity: 0.5;

        padding-top: 150px;

        display: flex;
        justify-content: center;
    }

    .header-container {
        text-align: center;
    }

    .content-container {
        display: flex;
        padding: 40px;

        .left-container {
            flex-basis: 50%;

            .credit-card-container {
                margin-bottom: 24px;

                .credit-card-subtitle {
                    color: $lively-blue-400;
                    margin-bottom: 24px;
                }

                .stripe-card-element {
                    border: 1px solid $grey-200;
                    border-radius: 6px;
                    padding: 10px 16px;
                }
            }

            .promo-code-container {
                .promo-code-subtitle {
                    color: $lively-blue-400;
                    margin-bottom: 24px;
                }
            }
        }

        .right-container {
            flex-basis: 50%;
            margin-left: 24px;

            button {
                background-color: $lively-blue-500;
                border: none;
                border-radius: 4px;
                font-weight: 500;
                font-size: 16px;
                height: 40px;

                &:focus {
                    background-color: $lively-blue-400;
                }

                &:hover {
                    background-color: $lively-blue-400;
                }

                &:disabled {
                    background-color: $grey-100;
                }
            }

            .payment-summary-container {
                .payment-summary-subtitle {
                    color: $lively-blue-500;
                    margin-bottom: 24px;
                }

                .payment-summary-details-container {
                    padding: 16px 0;
                    margin-bottom: 24px;

                    border: 1px solid $grey-200;
                    border-radius: 8px;

                    .payment-summary-detail {
                        padding: 0 40px;
                        margin-top: 24px;
                        margin-bottom: 24px;

                        display: flex;
                        justify-content: space-between;

                        &.grand-total {
                            border-top: 1px solid $grey-200;
                            padding-top: 24px;
                        }
                    }

                    .payment-grand-total-disclaimer {
                        font-style: italic;
                        color: $grey-500;
                        font-size: 12px;
                        padding-left: 32px;
                    }
                }
            }
        }
    }
}
