.container {
    .header {
        margin-bottom: 24px;
    }

    .cards-container {
        display: flex;
        justify-content: space-between;
        gap: 20px;
        margin-bottom: 48px;
        flex-wrap: wrap;
    }
}
