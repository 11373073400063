@import 'common/Common.scss';

.container {
    :global {
        .ant-list-items {
            display: flex;
            flex-wrap: wrap;
            gap: 36px;
        }
    }

    min-height: calc(
        100vh - #{$navigation-bar-height} + 2px
    ); //1px to account for navigation bar border thickness
    width: 100%;
}

.loading-container {
    padding-top: 64px;
    display: flex;
    justify-content: center;
}
