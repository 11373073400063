@import 'common/Common';
@import '../Common';

.container {
    display: flex;
    justify-content: center;
    padding: 0 72px;
    width: 100%;
    margin-bottom: 72px;

    @media screen and (max-width: 768px) {
        padding: 0;
    }

    .content-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        .title {
            margin: 48px 0 24px 0;
            display: flex;
            text-align: center;
            padding: 0 48px;
            justify-content: center;

            @media screen and (max-width: 480px) {
                font-size: 24px;
                line-height: 40px;
            }
        }

        .video-container {
            display: flex;
            justify-content: center;
            width: 75%;

            @media screen and (max-width: 768px) {
                width: 90%;
            }
        }
    }
}
