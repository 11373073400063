@import 'common/Common';

.editor-container {
    background-color: white;
    height: 100%;
    overflow-y: auto;

    :global {
        .rdw-editor-wrapper {
            display: flex;
            flex-direction: column;
            height: 100%;

            .rdw-editor-toolbar {
                border: none;
                border-bottom: 1px solid lightgray;
            }

            .rdw-editor-main {
                height: 95%;
            }

            .rdw-option-active {
                background-color: lightgray;
                border: none;
            }
        }
    }

    .overlay-spin-container {
        position: absolute;
        padding-top: 250px;
        background-color: rgba(255, 255, 255, 0.6);
        width: 100%;
        z-index: 2; // overlay spin loader on top of editor
        display: flex;
        justify-content: center;
    }

    .toolbar {
        padding: 8px 0 0 10px;
        margin-bottom: 10px;

        :global {
            //for each buttons in toolbar except for font size
            .rdw-option-wrapper {
                width: 36px;
                height: 36px;
                border: none;
                margin: 0;

                > img {
                    @include img-filter-grey-500;
                    padding: 4px;
                    width: 100%;
                }

                &:hover {
                    box-shadow: none;
                    > img {
                        @include img-filter-lively-blue-400;
                    }
                }
            }

            .rdw-option-active {
                background-color: transparent;
                box-shadow: none;
                img {
                    @include img-filter-lively-blue-500;
                }
            }

            //for font size button
            .rdw-dropdown-wrapper {
                &:hover {
                    box-shadow: none;
                }

                border: none !important;
                width: 16px;

                .rdw-dropdown-optionwrapper {
                    width: 60px;
                    overflow: hidden;

                    &:hover {
                        box-shadow: none;
                    }

                    .rdw-dropdownoption-highlighted {
                        background-color: white;
                        color: $lively-blue-400;
                    }
                }

                .rdw-dropdown-selectedtext {
                    color: $lively-blue-500;

                    > img {
                        @include img-filter-grey-500;
                        padding: 5px 0 5px 5px;
                        width: 100%;
                        height: 100%;
                    }
                }
            }

            .rdw-fontsize-wrapper {
                &:hover {
                    img {
                        @include img-filter-lively-blue-400;
                    }
                }
            }

            .rdw-dropdown-carettoopen {
                display: none;
            }

            .rdw-dropdown-carettoclose {
                display: none;
            }

            //for color picker
            .rdw-colorpicker-wrapper {
                .rdw-colorpicker-modal {
                    left: -50px;
                    width: 250px;
                    height: 250px;

                    .rdw-colorpicker-modal-options {
                        overflow: hidden;
                        display: flex;
                        justify-content: center;

                        .rdw-colorpicker-option {
                            box-shadow: none;

                            &:hover {
                                background-color: $grey-100;
                            }
                        }
                    }
                }
            }

            .rdw-emoji-modal {
                left: -90px;
                height: 250px;
                display: flex;
                justify-content: center;
            }
        }
    }

    .editor {
        padding: 0 30px;
    }

    // The default behaviour still shows the placeholder when ordered/unordered list is toggled on.
    // As encouraged in the doc, we use this CSS is toggled on and off for the placeholder behaviour
    // https://draftjs.org/docs/api-reference-editor/#placeholder
    .hide-placeholder {
        :global {
            .public-DraftEditorPlaceholder-root {
                display: none;
            }
        }
    }

    .read-only-content {
        padding: 30px;
    }
}
