@import 'common/Common';
@import '../../Common';

.container {
    .title-bar {
        @include title-bar;
    }

    .content {
        margin: 24px 24px 0 24px;

        > input {
            margin-bottom: 12px;
            border-radius: 4px;
        }

        .button-container {
            display: flex;
            justify-content: center;
            margin-top: 16px;

            .submit-button {
                background-color: $lively-blue-500;
                color: white;
                border-radius: 4px;
                font-weight: 500;
                border: none;

                &:hover {
                    background-color: $lively-blue-400;
                }
            }
        }
    }
}

.back {
    position: absolute;
    left: 16px;
    top: 10px;
    color: white;
    cursor: pointer;

    &:hover {
        color: white;
    }
}
