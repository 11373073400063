@import 'common/Common';

.container {
    background-color: white;
    width: 360px;
    height: 100%;

    .title {
        @include public-subtitle-1;
        margin-bottom: 24px;
    }

    .content {
        @include public-body-text-2;
    }
}
