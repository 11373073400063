@import 'common/Common';

.container {
    :global {
        .ant-list {
            margin-top: 64px;
        }

        .ant-list-item {
            padding: 0;
            margin-bottom: 24px;
            // "important" is needed, else the default .antd-list-item does not show last item's border-bottom
            border-bottom: 1px solid $grey-100 !important;
        }
    }
}
