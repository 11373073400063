@import 'common/Common';
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
@import '~antd/dist/antd.css';
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

.app-layout {
    .content {
        min-height: calc(
            100vh - #{$navigation-bar-height} - #{$footer-height} + 2px
        ); // 2px to account for the border
        background-color: white;
    }
}
