@import 'common/Common';

.button {
    margin-top: 20px;
}

.modal-container {
    padding: 40px;

    .modal-title {
        color: $lively-blue-500;
        margin-bottom: 24px;
    }

    .card-element-container {
        margin-bottom: 24px;

        .card-element {
            border: 1px solid $grey-200;
            border-radius: 6px;
            padding: 10px 16px;
        }
    }

    .cta-container {
        display: flex;

        .submit {
            margin-left: 15px;
        }
    }
}
