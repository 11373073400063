@import 'common/Common';

// index.tsx
.loading-container {
    padding-top: 150px;
    display: flex;
    justify-content: center;
}

.error-container {
    padding: 100px;
    text-align: center;

    .error-retry {
        display: flex;
        justify-content: center;
        margin-top: 50px;

        .error-retry-cta {
            width: 150px;
        }
    }
}

.container {
    padding: 64px 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    .content {
        width: 80%;

        .header-container {
            margin-bottom: 64px;
        }

        .sub-header-container {
            margin-bottom: 24px;
            color: $lively-blue-500;
        }

        .subscription-plans-container {
            display: flex;
            flex-wrap: wrap;
            gap: 40px 40px;
            margin-bottom: 160px;
        }

        .highlight {
            color: $lively-blue-500;
            font-weight: bold;
        }

        .strike {
            text-decoration: line-through;
        }
    }
}
