@import 'common/Common.scss';

@mixin section-title {
    @include public-header-2;
    padding: 0 20px;
    width: 100%;
    text-align: center;
}

.container {
    background-color: $lively-blue-100;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 20px 60px 20px;

    .section-title {
        @include public-header-2;
        width: 100%;
        text-align: center;
        padding-top: 48px;
        margin-bottom: 32px;
    }

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 32px;

        > div {
            @include public-body-text-2;
            margin-bottom: 8px;
            text-align: center;
        }
    }
}
