@import 'common/Common';

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 24px;
    margin-top: 48px;

    @media only screen and (min-width: 768px) {
        width: 700px;
    }

    .spin-container {
        width: 100%;
        padding-top: 50px;
        display: flex;
        justify-content: center;
    }

    .content-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .title {
            width: 100%;
            margin: 64px 0 8px 0;
            color: $dark;
        }

        .subtitle {
            width: 100%;
            color: $grey-400;
            margin-bottom: 48px;
        }

        .score-tag-container {
            display: flex;
            justify-content: flex-end;

            .score-tag {
                background-color: $lively-blue-500;
                color: white;
                width: 254px;
                height: 64px;
                border-radius: 8px 8px 0 0;

                font-size: 32px;
                font-weight: 600;

                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        .quiz-card-container {
            max-height: 1200px;
            overflow-y: scroll;
        }

        .buttons-footer {
            display: flex;
            justify-content: center;
            width: 100%;
            margin: 10px 0 40px 0;

            .back-to-quiz-challenge {
                font-weight: 400;
            }
        }
    }
}
