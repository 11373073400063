.modal-container {
    display: flex;
    flex-direction: column;
    padding: 50px;

    .spin-container {
        display: flex;
        justify-content: center;
    }

    .text-container {
        text-align: center;
    }
}
