@import 'common/Common';

.container {
    padding: 40px;

    .header-container {
        color: $lively-blue-500;
        margin-bottom: 24px;
    }

    .cta-container {
        display: flex;
        justify-content: flex-end;
        margin-top: 24px;

        .cancel-cta {
            margin-right: 15px;
        }
    }
}

.success-container {
    padding: 40px;

    .header-container {
        margin-bottom: 24px;
    }

    .subtitle-container {
        color: $lively-blue-500;
    }
}
