.container {
    .header {
        margin-bottom: 24px;
    }

    .cards-container {
        display: flex;
        justify-content: space-between;
        gap: 20px;
        margin-bottom: 48px;
        flex-wrap: wrap;
    }
}

.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 40px;
    margin-bottom: 48px;
}

.no-content-container {
    margin-bottom: 48px;
}
