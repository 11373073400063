@import 'common/Common';

.container {
    position: relative;

    .manage-subscription-container {
        position: absolute;
        right: 0;
        // -24px margin bottom from title in MyAccountV2
        // -48px padding from container in MyAccountV2
        // +15px padding from cta in CancelSubscription
        top: calc(-24px - 48px + 15px);
    }

    .subscription-info {
        margin-bottom: 40px;
    }
    .last-payment-col {
        .view-invoice {
            // margin-left: 15px; // reinstate when date can be displayed
            text-decoration: underline;
            color: $lively-blue-400;
            cursor: pointer;
        }
    }
}

.error-container,
.no-active-container {
    .title {
        text-align: center;
    }

    .cta-container {
        display: flex;
        justify-content: center;
        margin-top: 24px;

        .cta {
            width: 150px;

            &.long {
                width: 250px;
            }
        }
    }
}
