.container {
    .banner {
        display: flex;
        justify-content: center;
        margin-bottom: 15px;
    }

    .loading-container {
        display: flex;
        justify-content: center;
        margin-top: 50px;
    }

    .videos-container {
        display: flex;
        flex-wrap: wrap;
    }

    .load-more-container {
        display: flex;
        justify-content: center;
        margin-top: 20px;
    }

    .request-container {
        margin-top: 25px;
        margin-bottom: 25px;
        text-align: center;
        font-size: 20px;
        font-weight: 500;

        .request-cta {
            color: #065fd4;

            &:hover {
                text-decoration: underline;
                cursor: pointer;
            }
        }

        .disclaimer {
            color: grey;
            font-size: 14px;
            font-weight: 400;
            font-style: italic;
        }
    }
}
