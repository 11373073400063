@import 'common/Common.scss';

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100vw;
    margin-bottom: 48px;

    .section-title {
        @include public-section-title;
    }

    .content {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 40px;

        .image-container {
            width: 800px;
            display: flex;
            justify-content: center;

            @media screen and (max-width: 768px) {
                width: 100%;
                padding: 0 12px;
            }
        }

        .text-container {
            display: flex;
            justify-content: center;
            width: 600px;
            padding-top: 12px;

            .text {
                @include public-body-text-2;

                @media screen and (max-width: 768px) {
                    width: 100%;
                    padding: 0 36px;
                }
            }
        }
    }
}
