@import 'common/Common';

.container {
    padding: 64px 0;
    display: flex;
    justify-content: center;

    .content {
        width: 1072px;

        .header {
            margin-bottom: 24px;
            color: $dark;

            .title {
                margin-bottom: 24px;
            }
        }
    }

    @media screen and (max-width: 1080px) {
        .content {
            width: 80%;
        }
    }
}
