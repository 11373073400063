.terms-of-service {
    padding: 18px 24px;

    .header {
        font-size: 24px;
    }

    ol {
        list-style-type: none;
        counter-reset: item;
        margin: 0;
        padding: 0;

        &.alphabetical-list {
            list-style-type: lower-alpha;
            padding-left: 24px;
        }

        li:not(.alphabetical) {
            display: table;
            counter-increment: item;
            margin-bottom: 0.6em;
        }

        li:not(.alphabetical):before {
            content: counters(item, '.') '. ';
            display: table-cell;
            padding-right: 0.6em;
        }

        li:not(.alphabetical):before {
            content: counters(item, '.') '. ';
            display: table-cell;
            padding-right: 0.6em;
        }

        li li {
            margin: 0;
        }

        li li:not(.alphabetical):before {
            content: counters(item, '.') ' ';
        }
    }
}
