@import 'common/Common.scss';

.container {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 40px;
    background-color: $lively-blue-100;

    @media only screen and (max-width: 480px) {
        padding: 40px 12px;
    }

    .content-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 1200px;
        margin-bottom: 32px;

        h1 {
            @include public-section-title;
            margin-bottom: 40px;
        }
    }
}
