@import 'common/Common';

.button {
    margin-top: 20px;
}

.modal-container {
    :global {
        .ant-modal-content {
            padding: 20px;
            border-radius: 16px;
        }
    }

    .modal-title {
        color: $lively-blue-500;
        margin-bottom: 24px;
    }

    .input-container {
        display: flex;
        margin-bottom: 24px;

        input {
            margin-right: 16px;
            border-radius: 4px;
        }

        button {
            background-color: $lively-blue-500;
            border: none;
            border-radius: 4px;
            font-weight: 500;

            &:hover {
                background-color: $lively-blue-400;
            }
        }
    }
}
