@import 'common/Common';

.navigation-bar-container {
    :global {
        .ant-layout-header {
            position: relative; // box-shadow will be hidden without this
            display: flex;
            padding: 30px 60px 0 60px;
            justify-content: space-between;
            background-color: white;
            box-shadow: 0 1px 5px 0 #e1eafc;
            height: 100%;
        }

        // to target and overwrite the underline
        .ant-menu-item:hover::after {
            border-bottom: 2px solid $lively-blue-500 !important;
        }

        // "important" necessary to override antd's default color
        .ant-menu-item-active {
            a {
                color: $lively-blue-500 !important;
            }
        }

        // "important" necessary to override antd's default color
        .ant-menu-item-selected {
            // to target and overwrite the underline
            &::after {
                border-bottom: 2px solid $lively-blue-500 !important;
            }

            a {
                color: $lively-blue-500 !important;
                font-weight: 500;
            }
        }

        .ant-alert-message {
            font-family: 'Poppins', sans-serif;
        }
    }

    .navigation-bar {
        border-bottom: 1px solid #f0f0f0;
        position: relative;

        .logo-container {
            display: flex;
            justify-content: center;
            box-sizing: border-box;
            position: absolute;
            bottom: 2px; // to align logo with menu item
        }

        .menu {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            text-align: right;
        }
    }
}
