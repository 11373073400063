@import 'common/Common';

.container {
    min-height: calc(100vh - #{$navigation-bar-height});
    display: flex;

    flex-direction: column;
    width: 100%;

    align-items: center;

    .header {
        text-align: center;
        max-width: 700px;
        display: flex;
        flex-direction: column;
        padding: 0 20px;
        margin-bottom: 40px;

        .title {
            @include public-header-2;
            margin-top: 120px;
            margin-bottom: 40px;
        }

        .subtitle {
            font-family: Roboto, sans-serif;
            font-size: 20px;
            line-height: 24px;
            letter-spacing: 0.5px;
            font-weight: 500;
            color: $dark;
        }
    }

    .subscription-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        flex-grow: 1;
        width: 100%;
        margin-bottom: 160px;
        gap: 40px 40px;
    }
}
