@import 'common/Common';
@import '../Common';

.container {
    display: flex;
    justify-content: center;
    padding-left: 36px;

    .content-container {
        .title {
            margin: 48px 0 24px 0;
            text-align: center;
            display: flex;
            justify-content: center;
            padding: 0 72px;
        }

        .file-container {
            display: flex;
            justify-content: center;
        }
    }
}
