@import 'common/Common';

@mixin title-bar {
    background-color: $lively-blue-500;
    color: white;
    padding: 0 24px;
    height: 40px;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    font-weight: 500;
}
