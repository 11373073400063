@import 'common/Common.scss';

.container {
    min-height: calc(100vh - #{$navigation-bar-height});
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .section-title {
        @include public-section-title;
    }

    .testimonials-section {
        width: 100%;
        margin-bottom: 128px;
    }
}
