@import 'common/Common';

.subscription-box {
    display: flex;
    flex-direction: column;
    width: 360px;
    min-height: 480px;
    padding: 40px;
    border: 1px solid $grey-100;
    border-radius: 8px;
    background-color: white;

    @media (max-width: 450px) {
        width: 300px;
    }

    button {
        background-color: $lively-blue-500;
        border: none;
        border-radius: 4px;
        font-weight: 500;
        font-size: 16px;
        height: 40px;

        &:focus {
            background-color: $lively-blue-400;
        }

        &:hover {
            background-color: $lively-blue-400;
        }

        &:disabled {
            background-color: $grey-100;
        }
    }

    &.is-disabled {
        opacity: 0.5;
    }

    .subscription-box-header {
        margin-bottom: 24px;

        .subscription-box-header-span {
            color: $grey-200;
        }
    }

    .price-spacer {
        height: 51px;
    }

    .subscription-box-perks-container {
        flex-grow: 1;
    }

    .subscription-box-cta {
        align-self: flex-end;
    }
}
