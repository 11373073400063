@import 'common/Common';

$error-red: #c0302e;

.promo-code-container {
    display: flex;
    align-items: flex-start;

    .promo-code-input-container {
        flex-basis: 70%;
        margin-right: 15px;

        .promo-code-input {
            padding: 7px 16px;

            border: 1px solid $grey-200;
            border-radius: 6px;

            &.has-error {
                border-color: $error-red;
            }
        }

        .validation-error {
            font-family: 'Roboto', sans-serif;
            font-size: 12px;
            line-height: 21px;
            color: $error-red;
        }

        .validation-forever {
            font-family: 'Roboto', sans-serif;
            font-size: 12px;
            line-height: 21px;
            color: $lively-blue-500;
        }
    }

    .promo-code-input-cta {
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        color: $lively-blue-500;

        border: 1px solid $lively-blue-500;
        border-radius: 4px;

        padding: 6px 16px;

        cursor: pointer;

        &.clear-code {
            color: $error-red;
            border-color: $error-red;
        }
    }
}
