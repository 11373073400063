@import 'common/Common.scss';

.container {
    // to ensure the background image covers the entire screen regardless of screen size,
    // padding-bottom is used to set the ratio according to the screen size
    // the ratio is derived by: width / height of image
    height: 0;
    padding-bottom: 57%;

    width: 100%;
    display: flex;
    justify-content: flex-end;
    position: relative;
    background-image: url('../../../static/landing/cloudscore-banner.svg');
    background-position: 30% 20%;
    background-repeat: no-repeat;
    background-size: cover;

    @media only screen and (max-width: 1080px) {
        min-height: calc(
            100vh - #{$navigation-bar-height} + 2px
        ); //2px to account for border height
        padding-bottom: 0;
        background-position: 70%;
        justify-content: flex-end;
    }

    @media only screen and (max-width: 768px) {
        justify-content: center;
    }

    .content-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 60%;
        padding-top: 120px;

        .content {
            width: 80%;
            display: flex;
            flex-direction: column;

            .title {
                @include public-header-1;
                color: #000000d9;
                margin-bottom: 24px;
            }

            .subtitle {
                @include public-body-text-1;
                color: #000000d9;
                margin-bottom: 40px;
            }
        }

        .cta-button-container {
            margin-bottom: 36px;
            display: flex;
            width: 100%;

            .button-group {
                width: min-content;

                .emphasis {
                    font-size: 16px;
                    font-weight: 700;
                    text-align: center;
                    margin: 4px 0;
                }
            }
        }

        @media only screen and (max-width: 1080px) {
            width: 70%;
        }

        @media only screen and (max-width: 768px) {
            width: 85%;
            align-items: center;

            .content {
                width: 100%;
                text-align: center;

                .cta-button-container {
                    justify-content: center;
                }
            }
        }

        @media only screen and (max-width: 480px) {
            padding-top: 80px;

            .content {
                .cta-button-container {
                    display: flex;
                    justify-content: center;
                }
            }
        }
    }
}
