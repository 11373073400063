@import 'common/Common';

.container {
    width: 100%;
    margin-bottom: 24px;
    height: 100%;

    :global {
        .ant-list-item {
            padding: 0;
            display: flex;
        }
    }

    .content-container {
        width: 100%;
        min-height: 88px;
        border: 1px solid $grey-100;
        border-radius: 8px;
        display: flex;
        flex-wrap: wrap-reverse;

        .text-container {
            display: flex;
            align-items: center;
            margin: 12px;
            padding: 20px;
            min-width: min-content;

            .text {
                color: $dark;
                margin: 0;
            }
        }

        .tag-container {
            display: flex;
            justify-content: flex-end;
            height: 100%;
            background-color: #8c1911;
            border-radius: 4px;
            align-self: flex-end;

            .tag {
                display: flex;
                width: 120px;
                height: 29px;
                background-color: $lively-blue-500;
                color: white;
                border-radius: 4px;
                padding: 4px 8px;
            }
        }
    }

    .wrong {
        background-color: #eacdcc;
        border: 1px solid #c0302e;
    }

    .correct {
        background-color: #ccead9;
        border: 1px solid #2ec06b;
    }
}
