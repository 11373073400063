@import 'common/Common';

.container {
    position: relative;
}

.error-container {
    .title {
        text-align: center;
    }

    .cta-container {
        display: flex;
        justify-content: center;
        margin-top: 24px;

        .cta {
            width: 150px;

            &.long {
                width: 250px;
            }
        }
    }
}
