@import 'common/Common.scss';

.container {
    :global {
        .ant-modal-content {
            border-radius: 8px;
        }
    }

    .icon-container {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        margin: 12px 0 20px 0;

        svg {
            width: 100px;
            height: 100px;
        }
    }

    .content-container {
        width: 100%;
        min-height: 100px;
        padding: 16px;

        .title {
            margin-bottom: 8px;
        }

        .loading-container {
            .spin-container {
                width: 100%;
                display: flex;
                justify-content: center;
                margin-bottom: 16px;
            }
            .text {
                > div:first-child {
                    margin-bottom: 8px;
                }
            }
        }

        .error-container {
            .text {
                > div:first-child {
                    margin-bottom: 4px;
                }
            }

            .error {
                color: $arc-bright-red;
            }
        }

        .success-container {
            .success {
                color: #51b917;
            }
        }
    }

    .highlight {
        font-weight: 500;
    }

    .blue {
        color: $lively-blue-500;
    }
}
