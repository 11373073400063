@import 'common/Common';

.container {
    padding: 50px;

    .navbar {
        display: flex;
        margin-bottom: 25px;

        .navitem {
            margin-right: 25px;
            padding: 5px 15px;
            min-width: 150px;
            border: 1px solid rgba(0, 0, 0, 0.5);
            border-radius: 5px;
            text-align: center;

            &:hover {
                cursor: pointer;
                background-color: $arc-medium-blue;
                border-color: white;
                color: white;
            }

            &.active {
                background-color: $arc-blue;
                border-color: white;
                color: white;
            }

            &.disabled {
                background-color: rgba(0, 0, 0, 0.3);
                border-color: rgba(0, 0, 0, 0.2);
                color: white;

                &:hover {
                    cursor: default;
                    color: white;
                }
            }
        }

        .faq {
            // move it closer to the left
            margin-left: -15px;
            padding: 5px 15px;
            font-style: italic;
            color: #808080;

            &:hover {
                cursor: pointer;
                color: #404040;
            }
        }
    }
}

.faq-container {
    padding: 15px;

    .faq-title {
        text-align: center;
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 15px;
    }

    .faq-item-title {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 5px;
    }

    .faq-item-description {
        font-size: 14px;
        font-style: italic;
        color: #404040;
        margin-bottom: 25px;
        padding: 0 10px;
    }
}
