@import 'common/Common';

@mixin generic-container {
    padding: 48px;
    border: 1px solid $grey-200;
    border-radius: 5px;
    margin-top: 40px;
    margin-bottom: 40px;

    .title {
        color: $lively-blue-500;
        margin-bottom: 24px;
    }
}

.container {
    width: 716px;
    margin: 0 auto;

    @media (max-width: 768px) {
        width: 100%;
    }

    .header-container {
        margin-top: 64px;
        margin-bottom: 64px;
    }

    .profile-container,
    .password-container,
    .subscription-container {
        @include generic-container;

        .divider {
            border: 1px solid $grey-100;
            margin: 40px 0 24px 0;
        }
    }
}
