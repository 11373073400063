.confirm-save-modal {
    :global {
        .ant-modal {
            width: 478px !important;

            .ant-modal-content {
                padding: 48px;
                display: flex;
                flex-direction: column;
                align-items: center;

                .ant-modal-body {
                    padding: 0;
                    width: 382px;
                    box-sizing: border-box;

                    height: 48px;
                    margin-bottom: 24px;
                }
            }

            .ant-modal-footer {
                border: none;
                padding: 0;
                margin: 0;
                display: flex;
                width: 382px;
            }
        }
    }
}
