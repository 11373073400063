@import 'common/Common.scss';

.container {
    width: 100%;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 64px 0;

    .section-title {
        @include public-section-title;
    }

    .content-container {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 100px;

        @include public-body-text-1;
        font-weight: 500;

        .data-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            width: 350px;

            .percentage {
                font-size: 72px;
                font-weight: 700;
                margin: 72px 0;
            }

            .cloudscore {
                color: $arc-blue;
            }

            .global {
                color: $grey-200;
            }
        }

        .vs {
            font-size: 32px;
        }

        @media screen and (max-width: 768px) {
            flex-direction: column;
            gap: 60px;

            .data-container {
                padding: 0 24px;

                .percentage {
                    margin: 48px 0;
                }
            }
        }

        @media screen and (max-width: 480px) {
            .data-container {
                width: 100%;
            }
        }
    }
}
