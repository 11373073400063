@import 'common/Common';

.authentication {
    padding: 15px;
    min-height: 100vh;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    &__text {
        text-align: center;
    }

    .login-button {
        background-color: $lively-blue-500;
        border-style: none;
        border-radius: 4px;

        &:hover {
            background-color: $lively-blue-400;
        }
    }

    &__links {
        display: flex;
        justify-content: center;

        .sign-up-button {
            background-color: #ffe018;
            border-style: none;
            border-radius: 4px;
            color: $lively-blue-500;
            font-weight: bold;
            padding: 0 24px;

            &:hover {
                background-color: lighten(#ffe018, 5%);
                color: $lively-blue-400;
            }
        }

        .forgot-password {
            color: $lively-blue-500;
        }
    }
}

.authentication-card {
    width: 400px;

    &__content {
        width: 100%;
    }
}

.sign-up-form-container {
    @media only screen and (max-width: 480px) {
        // because we don't really handle mobile view
        // currently if in mobile view, the navbar becomes very thick
        // but the main containers dont cater for that extra height
        // so we need to manually push the content down
        margin-top: 100px;
    }

    .sign-up-form-banner {
        width: 500px;
        margin: 5px auto 35px;
        padding: 25px;
        box-shadow: 1px 1px 10px 2px rgba(0, 0, 0, 0.2);
        border: 1px solid $grey-100;

        @media only screen and (max-width: 480px) {
            width: 350px;
        }

        .sign-up-form-block {
            display: block;
        }

        .sign-up-form-subtitle {
            margin-top: 15px;
        }
    }

    .sign-up-form-auth-card-container {
        display: flex;
        justify-content: center;
    }
}
