@import 'common/Common';

.icon {
    width: 32px;
    height: 32px;
    border-radius: 16px;

    font-size: 16px;
    font-weight: 500;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;
}

.answered {
    background-color: $lively-blue-100;
    color: $lively-blue-500;
    border: 2px solid $lively-blue-500;
}

.unanswered {
    background-color: white;
    color: $lively-blue-500;
    border: 2px dashed $lively-blue-500;
}

.current {
    background-color: $lively-blue-500;
    color: white;
    border: 2px dashed $lively-blue-500;
}
