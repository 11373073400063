@import 'common/Common.scss';

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 131px;

    .section-title {
        @include public-section-title;
        margin-bottom: 16px;
    }

    .key-features-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 30px;
        width: 100%;

        > div {
            width: 300px;
            padding: 20px;
            height: 450px;
        }
    }
}
