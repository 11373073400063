@import 'common/Common';

.container {
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;

    // to ensure texts or elements in NotesCard does not break in half while printing
    @media print {
        display: block;
    }

    .topic-container {
        display: flex;
        align-items: center;
        gap: 40px;

        :global {
            .ant-typography {
                margin-bottom: 0;
            }

            .anticon {
                width: 24px;
                height: 24px;
                margin-left: 8px;

                svg {
                    transform: rotateZ(-90deg);
                    color: $lively-blue-400;
                }
            }

            .ant-tag {
                user-select: none;
            }
        }

        a {
            color: $dark;

            &:hover {
                color: $lively-blue-500;
                text-decoration: underline;
            }
        }
    }

    .notes {
        color: $dark;
    }

    .last-updated {
        color: $grey-500;
        margin-bottom: 40px;
    }
}
