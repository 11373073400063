@import 'common/Common';

.container {
    .reinstate-subscription-cta {
        color: $lively-blue-500;
        cursor: pointer;
        padding: 10px 15px;
    }
}

.reinstate-subscription-modal {
    padding: 40px;
    text-align: center;

    .reinstate-subscription-button-group {
        display: flex;
        justify-content: center;
        margin-top: 25px;

        .reinstate-subscription-yes {
            margin-left: 10px;
        }
    }
}
