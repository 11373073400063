@import 'common/Common';

.container {
    width: 100%;
    display: flex;
    gap: 20px;

    @media only screen and (max-width: 768px) {
        flex-direction: column;
    }

    .image-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 150px;

        @media only screen and (max-width: 768px) {
            width: 100%;
        }

        .title {
            color: $dark;
            font-family: Poppins, sans-serif;
            font-size: 18px;
            line-height: 27px;
            font-weight: 700;
            margin-bottom: 8px;
            text-align: center;
        }
    }

    .content-container {
        width: 100%;

        .text-container {
            width: 100%;
            height: 100%;
            padding: 32px 24px;
            border: 1px solid $grey-100;
            border-radius: 8px;
            cursor: pointer;
            display: flex;
            align-items: center;

            .description {
                color: $dark;

                .highlight {
                    font-weight: 500;
                }
            }

            &:hover {
                box-shadow: 0 3px 25px 0 #cccccc40;
            }
        }
    }
}
