@import 'common/Common.scss';

.spin-container {
    padding-top: 100px;
    display: flex;
    justify-content: center;
}

.error-container {
    padding-top: 150px;
    text-align: center;
}

.container {
    padding: 24px;

    .header {
        text-align: center;
        margin: 24px 0;
    }

    .fav-container {
        width: 75%;
        margin: 0 auto;
        padding-top: 40px;

        .thumbs-up {
            cursor: pointer;
        }

        .fav-items {
            border: 1px solid $grey-200;
            border-bottom: none;
            padding: 10px 35px;
            display: flex;
            justify-content: space-between;
            align-content: center;

            &:last-child {
                border-bottom: 1px solid $grey-200;
            }

            .file-name {
                flex-grow: 1;
                margin-left: 25px;
                align-self: center;
                cursor: pointer;
            }
        }
    }

    // to override svg path colors of two tone icons
    .thumbs-active {
        svg {
            path:nth-child(2) {
                fill: $lively-blue-400;
            }
        }
    }
}
