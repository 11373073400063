@import 'common/Common.scss';

.container {
    min-height: calc(100vh - #{$navigation-bar-height} + 2px);
    padding: 20px;
    width: 100%;

    .content-container {
        display: flex;
        flex-direction: column;

        .button-container {
            display: flex;
            justify-content: center;
        }

        .title {
            color: $dark;
            margin-bottom: 0;
        }
    }

    @media (min-width: 600px) {
        width: 60%;
    }
}
