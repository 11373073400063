@import 'common/Common';

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60%;
    margin: 0 auto;

    .spin-container {
        width: 100%;
        padding-top: 50px;
        display: flex;
        justify-content: center;
    }

    .content-container {
        width: 100%;

        .title {
            margin: 64px 0 40px 0;
            color: $dark;
        }

        .progress-indicator {
            margin-bottom: 64px;
        }

        .subtitle {
            margin-bottom: 48px;
        }

        .image-container {
            display: flex;
            justify-content: center;
            margin-bottom: 48px;
        }

        .buttons-footer {
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-top: 64px;
            padding: 40px 0;
            border-top: 1px solid $grey-100;

            > button {
                height: 40px;
                border-radius: 4px;

                color: $lively-blue-500;
                border-color: $lively-blue-500;

                &:hover {
                    color: $lively-blue-400;
                    border-color: $lively-blue-400;
                }

                &:disabled {
                    color: $grey-200;
                    border-color: $grey-200;
                }
            }

            .view-summary-button {
                background-color: $lively-blue-500;
                border: none;
                color: white;

                &:hover {
                    background-color: $lively-blue-400;
                    color: white;
                }

                &:disabled {
                    color: $grey-200;
                    border: 1px solid $grey-200;
                    background-color: #f5f5f5;
                }
            }
        }
    }
}
