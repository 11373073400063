@import 'common/Common';

.container {
    .cancel-subscription-cta {
        color: $arc-bright-red;
        cursor: pointer;
        padding: 10px 15px;
    }
}

.cancel-subscription-modal {
    padding: 40px;
    text-align: center;

    .cancel-subscription-button-group {
        display: flex;
        justify-content: center;
        margin-top: 25px;

        .cancel-subscription-yes {
            margin-left: 10px;
        }
    }
}
