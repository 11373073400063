@import 'common/Common';

.container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .content {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 120px 25px 0 25px;

        h1 {
            font-size: 60px;
            font-weight: 500;
            color: $dark;
            font-family: Poppins, sans-serif;
            text-align: center;
        }

        .text {
            text-align: center;
            width: 100%;
            font-family: Roboto, sans-serif;
            font-size: 20px;
            line-height: 24px;
            letter-spacing: 0.5px;

            p {
                margin-bottom: 8px;

                a {
                    color: $lively-blue-500;
                }
            }
        }

        .contact {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            width: 100%;
            font-weight: 500;
            font-size: 24px;
            line-height: 24px;
            margin: 88px 30px 50px 30px;
            gap: 30px 100px;

            a {
                color: $lively-blue-500;
            }

            svg {
                margin-right: 8px;
            }
        }
    }
}
