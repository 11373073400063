@import 'common/Common';

.container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .spin-container {
        width: 100%;
        padding-top: 50px;
        display: flex;
        justify-content: center;
    }

    .content-container {
        width: 100%;

        .question {
            margin-bottom: 28px;
        }

        .image-container {
            display: flex;
            justify-content: center;
            margin-bottom: 28px;
        }
    }

    .button-container {
        display: flex;
        justify-content: space-between;
        margin: 28px 0;

        button {
            font-family: Roboto, sans-serif;
            font-weight: 500;
            height: 40px;
            border-radius: 4px;

            &:disabled {
                background-color: #f5f5f5;
                border: 1px solid #d9d9d9;
                color: rgba(0, 0, 0, 0.25);
            }
        }

        .submit {
            background-color: $lively-blue-500;
            color: white;
            border: none;

            &:hover {
                background-color: $lively-blue-400;
            }

            &:disabled {
                background-color: #f5f5f5;
                border: 1px solid #d9d9d9;
                color: rgba(0, 0, 0, 0.25);
            }
        }

        .skip {
            background-color: white;
            color: $lively-blue-500;
            border: 1px solid $lively-blue-500;

            &:hover {
                color: $lively-blue-400;
                border: 1px solid $lively-blue-400;
            }

            &:disabled {
                background-color: #f5f5f5;
                border: 1px solid #d9d9d9;
                color: rgba(0, 0, 0, 0.25);
            }
        }
    }
}
