@import 'common/Common';

.container {
    text-align: center;
    font-size: 16px;
    color: $dark;

    .highlight {
        font-weight: 500;
    }

    > div {
        margin-bottom: 20px;
    }
}
