@import 'common/Common';

.container {
    font-size: 16px;
    color: $dark;
    width: 100%;

    :global {
        .ant-card {
            border-radius: 8px;
        }
    }
}
