@import 'common/Common.scss';

.container {
    :global {
        .ant-modal-content {
            border-radius: 8px;
        }
    }

    .content-container {
        height: 40px;
    }
}
