@import 'common/Common';

// !important is needed to override antd default styling
.remove-button {
    color: $error-red !important;
    font-weight: 500 !important;
    width: max-content !important;
    padding: 0 !important;

    &:hover {
        color: lighten($error-red, 10%) !important;
    }
}

.modal-container {
    :global {
        .ant-modal-content {
            border-radius: 8px;
        }
    }

    .text-container {
        padding: 20px;

        .name {
            font-weight: 700;
        }
    }

    button {
        border-radius: 4px;
        font-weight: 500;
    }

    .cancel {
        color: $lively-blue-500;
        border: none;

        &:hover {
            color: $lively-blue-400;
        }
    }

    .confirm {
        color: white;
        background-color: $lively-blue-500;
        border: none;

        &:hover {
            background-color: $lively-blue-400;
        }
    }
}
