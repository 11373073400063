@import 'common/Common';

.container {
    min-height: calc(100vh - 64px);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .header {
        display: flex;
        flex-direction: column;
        text-align: center;
        margin: 120px 0 67px 0;

        .header-text {
            font-family: Poppins, sans-serif;
            font-size: 60px;
            margin-bottom: 40px;
            line-height: 90px;
            font-weight: 500;
            color: $dark;
        }

        .subtitle {
            font-family: Roboto, sans-serif;
            font-size: 20px;
            line-height: 24px;
            letter-spacing: 0.5px;
            color: $dark;
        }
    }

    .content {
        width: 75%;

        .faq-container {
            font-family: Roboto, sans-serif;
            display: flex;
            flex-direction: column;
            width: 100%;
            margin: 0 auto;
            flex-grow: 1;

            .faq-header {
                text-align: center;
                font-size: 32px;
                font-weight: 700;
                margin-bottom: 36px;
            }

            .section-header {
                font-size: 40px;
                line-height: 60px;
                font-weight: 500;
                margin-bottom: 24px;
                color: $dark;
            }

            .question {
                font-size: 24px;
                font-weight: 500;
                line-height: 24px;
                letter-spacing: 0.5px;
                margin-bottom: 24px;
                color: $dark;
            }

            .answer {
                font-size: 18px;
                margin-bottom: 24px;
                line-height: 24px;
                letter-spacing: 0.5px;
                color: $dark;
            }

            .divider {
                margin: 16px 0 56px 0;
            }

            .last {
                margin-bottom: 160px;
            }
        }
    }
}
