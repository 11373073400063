@import 'common/Common';

.container {
    position: absolute;
    top: 0;
    left: 0;
    height: 500px;
    width: 100%;
    background-color: #ffffff20;
    border-radius: 8px;
    border: 1px solid $grey-100;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    backdrop-filter: blur(2px);
    margin: 0;

    .text {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 8px;
        text-align: center;
    }

    .buttons-container {
        display: flex;
        justify-content: space-around;
        width: 200px;

        :global {
            .ant-btn {
                border-radius: 4px;
                font-weight: 500;
            }
        }

        .cancel {
            &:hover {
                border-color: 1px solid $lively-blue-500;
                color: $lively-blue-500;
            }
        }
    }
}
