@import 'common/Common';

.container {
    display: flex;
    justify-content: space-between;
    margin-top: 44px;

    .info {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;

        .icon {
            margin-right: 16px;
        }
    }
}
