@import 'common/Common.scss';

.container {
    :global {
        .ant-btn-dangerous {
            color: $error-red;
            border-color: $error-red;

            &:hover,
            &:focus {
                border-color: lighten($error-red, 10%);
                color: lighten($error-red, 10%);
            }
        }
    }

    > button {
        font-family: Roboto, sans-serif;
        font-weight: 500;
        height: 40px;
        border-radius: 4px;
    }

    .default {
        background-color: white;
        color: $lively-blue-500;
        border: 1px solid $lively-blue-500;

        &:hover,
        &:focus {
            color: $lively-blue-400;
            border: 1px solid $lively-blue-400;
        }

        &:disabled {
            background-color: #f5f5f5;
            border: 1px solid #d9d9d9;
            color: rgba(0, 0, 0, 0.25);
        }
    }

    .primary {
        background-color: $lively-blue-500;
        color: white;
        border: none;

        &:hover,
        &:focus {
            background-color: $lively-blue-400;
            color: white;
            border: none;
        }

        &:disabled {
            background-color: #f5f5f5;
            border: 1px solid #d9d9d9;
            color: rgba(0, 0, 0, 0.25);
        }
    }

    .link {
        color: $lively-blue-500;
        border: none;
        box-shadow: none;

        &:hover,
        &:focus {
            color: $lively-blue-500;
        }

        &:disabled {
            background-color: #f5f5f5;
            border: 1px solid #d9d9d9;
            color: rgba(0, 0, 0, 0.25);
        }
    }
}
