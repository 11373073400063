@import 'common/Common.scss';

.container {
    min-height: calc(100vh - #{$navigation-bar-height} + 2px);
    display: flex;
    justify-content: center;

    @media (min-width: 480px) {
        .content-container {
            width: 70%;
        }
    }
}
