.background-container {
    background-color: #f5f5f5;

    .container {
        padding: 35px;

        .title-container {
            text-align: center;
            margin-bottom: 15px;
        }

        .image-container {
            display: flex;
            justify-content: center;
            margin-bottom: 25px;
        }

        .footer-container {
            display: flex;
            justify-content: center;
        }
    }
}
