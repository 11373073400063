@import 'common/Common.scss';

@mixin ibecons-cta {
    display: flex;
    justify-content: center;

    .cta {
        padding: 5px 25px;
        width: 250px;
        cursor: pointer;
        border: 1px solid black;
        font-size: 24px;
        text-align: center;
        background-color: white;

        &:hover {
            color: white;
            background-color: $arc-blue;
        }
    }
}

.ib-econs-v2-container {
    font-family: 'Poppins';

    .header {
        font-size: 40px;
        text-align: center;
        padding: 60px 85px 0 85px;
        margin-bottom: 60px;

        &.bold {
            font-weight: bold;
        }

        @media only screen and (max-width: 480px) {
            padding: 25px;
        }
    }

    .jumbotron {
        display: flex;
        justify-content: center;
        margin-bottom: 50px;
    }

    .cta-container {
        display: flex;
        justify-content: center;
        margin-bottom: 50px;

        .cta-1 {
            border: 1px solid black;
            font-size: 24px;
            background-color: #f1c232;
            padding: 5px 15px;
            cursor: pointer;

            &:hover {
                color: white;
                background-color: $arc-blue;
            }
        }
    }

    .paragraph {
        font-size: 22px;
        text-align: center;
        padding: 60px 85px 0 85px;
        margin-bottom: 60px;

        @media only screen and (max-width: 480px) {
            padding: 25px;
        }
    }

    .row {
        margin-bottom: 50px;

        .row-image {
            display: flex;
            justify-content: center;

            @media only screen and (max-width: 480px) {
                margin-bottom: 25px;
            }
        }

        .row-paragraph {
            font-size: 28px;
            text-align: center;
            padding: 0 50px;
            align-self: center;
        }
    }

    .banner {
        font-size: 40px;
        font-weight: bold;
        text-align: center;
        color: #085394;
        background-color: #f1c232;
        padding: 35px;
        margin-bottom: 50px;
    }

    .carousel-container {
        padding-left: calc((100vw - 498px) / 2);
        margin-bottom: 50px;

        @media only screen and (max-width: 480px) {
            padding-left: calc((100vw - 360px) / 2);
        }

        .testimonial-image {
            @media only screen and (max-width: 480px) {
                width: 360px !important;
            }
        }
    }
}

.container {
    background-color: white;

    .jumbotron {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        height: 850px;
        background-image: url('../../static/ibecons/ibecons_web_jumbo.svg');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;

        @media only screen and (max-width: 480px) {
            height: 500px;
            background-image: url('../../static/ibecons/ibecons_mobile_jumbo.svg');
        }

        .title-container {
            padding-top: 100px;
            text-align: center;

            @media only screen and (max-width: 480px) {
                padding: 25px;
            }

            .title {
                font-size: 40px;
                font-weight: bold;

                @media only screen and (max-width: 480px) {
                    font-size: 32px;
                }
            }
        }

        .ib-econs-cta-container {
            @include ibecons-cta;
            padding-bottom: 200px;

            @media only screen and (max-width: 480px) {
                padding-bottom: 100px;
            }
        }
    }

    .middle-block {
        padding: 50px;

        .middle-title-container {
            text-align: center;
            margin-bottom: 100px;

            .middle-title {
                font-size: 40px;

                @media only screen and (max-width: 480px) {
                    font-size: 24px;
                }
            }
        }

        .custom-card {
            padding: 25px 50px;
            text-align: center;

            .image-container {
                display: flex;
                justify-content: center;
                margin-bottom: 25px;
            }

            .custom-card-text {
                font-size: 24px;
            }
        }
    }

    .footer {
        padding: 50px;

        .footer-text {
            text-align: center;
            font-size: 24px;
        }

        .footer-cta-container {
            @include ibecons-cta;

            margin-top: 75px;
            margin-bottom: 50px;
        }
    }
}
