@import 'common/Common';
@import '../../Common';

.container {
    display: flex;
    flex-direction: column;
    height: 100%;

    .title-bar {
        @include title-bar;

        .title {
            padding: 0 50px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }

    .messages-container {
        display: flex;
        flex-direction: column-reverse;
        flex-grow: 1;
        padding: 16px;
        overflow-y: auto;
    }

    .resolve-container {
        display: flex;
        justify-content: center;
        width: 100%;
        user-select: none;

        .marked-as-resolved-text {
            color: #52c41a;
        }

        .button {
            width: 250px;
            color: $arc-bright-red;
            border: 1px solid $arc-bright-red;
            border-radius: 4px;

            &:hover {
                color: lighten($arc-bright-red, 10%);
                border: 1px solid lighten($arc-bright-red, 10%);
            }
        }
    }
}

.back {
    position: absolute;
    left: 16px;
    top: 10px;
    color: white;
    cursor: pointer;

    &:hover {
        color: white;
    }
}

.loading-container {
    height: 100%;
    display: flex;
    justify-content: center;
    padding-top: 80px;
}

.error-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 120px;
    font-size: 16px;
    text-align: center;

    .retry {
        background-color: $lively-blue-500;
        color: white;
        font-weight: 500;
        border-radius: 4px;
        border: none;
        margin: 8px;

        &:hover {
            background-color: $lively-blue-400;
        }
    }
}
