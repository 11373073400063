@import 'common/Common';

.container {
    font-family: 'Roboto', sans-serif;
    color: $dark;

    :global {
        .ant-drawer-title {
            color: $dark;
        }

        .ant-drawer-body {
            padding: 0;
        }

        .ant-drawer-footer {
            display: flex;
            justify-content: flex-end;
            padding: 24px;
        }

        .ant-btn-primary {
            background-color: $lively-blue-500;
            border-color: $lively-blue-500;

            &:hover {
                background-color: $lively-blue-400;
                border-color: $lively-blue-500;
            }

            &:disabled {
                background-color: $grey-100;
                border-color: $grey-100;
            }
        }

        .ant-btn-link {
            color: $lively-blue-500;

            &:hover {
                color: $lively-blue-400;
            }

            &:disabled {
                color: $grey-100;
            }
        }
    }

    .spin-container {
        position: absolute;
        padding-top: 250px;
        background-color: white;
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .error-container {
        padding: 150px 24px 0 24px;
        text-align: center;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: $messages-grey;

        > p {
            margin: 0;
        }

        > button {
            margin: 10px 0;
        }
    }

    .no-content-container {
        padding: 24px 24px 0 24px;
        height: 100%;
        display: flex;
        flex-direction: column;
        color: $messages-grey;
    }
}
