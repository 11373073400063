@import 'common/Common.scss';

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .section-title {
        @include public-section-title;
        max-width: 900px;
    }

    .image-container {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .button-container {
        margin: 32px 0 64px 0;
    }
}
