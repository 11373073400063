.container {
    padding: 25px;

    .title {
        font-size: 24px;
        font-weight: 500;
        margin-bottom: 25px;
    }

    .form-container {
        .form-prompt {
            font-weight: 500;
            margin-bottom: 10px;
        }

        .form-subjects {
            margin-bottom: 25px;
        }

        .form-input {
            max-width: 500px;
            margin-bottom: 25px;
        }

        .form-categories {
            max-width: 500px;
            margin-bottom: 25px;
        }
    }
}

.modal-container {
    padding: 25px;

    .modal-title {
        text-align: center;
        font-size: 24px;
        font-weight: 500;
        margin-bottom: 15px;
    }

    .modal-subtitle {
        text-align: center;
        font-size: 18px;
        margin-bottom: 25px;
    }

    .modal-cta {
        display: flex;
        justify-content: center;
    }

    .spin-container {
        display: flex;
        justify-content: center;
        margin-bottom: 25px;
    }

    .spin-text {
        text-align: center;
        font-size: 20px;
        font-weight: 500;
    }
}
