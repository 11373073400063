@import 'common/Common';

.container {
    user-select: none;
    flex-wrap: wrap;

    :global {
        .ant-steps-item {
            padding-left: 0 !important;
            .ant-steps-item-icon {
                margin: 0;

                .ant-steps-icon {
                    left: 0 !important;
                }
            }

            // override the line in between steps
            .ant-steps-item-title::after {
                left: 0;
                height: 2px;
                background-color: $lively-blue-500 !important;
            }
        }
    }
}
