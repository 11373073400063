@import 'common/Common';

.container {
    width: 897px;
    margin-left: 181px;

    .header {
        margin: 64px 0 48px 0;
        color: $dark;
    }

    .spin-container {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        padding-top: 200px;
    }
}
