@import 'common/Common';

.container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    position: fixed;
    right: 20px;
    bottom: 20px;

    @media (max-width: 768px) {
        bottom: 100px;
    }

    @media (max-width: 480px) {
        bottom: 64px;
        left: 20px;
        right: inherit;
    }

    .button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 200px;
        height: 40px;
        // important is required to overwrite default antd button styling
        background-color: $lively-blue-500 !important;
        color: white !important;
        border-radius: 8px;
        font-weight: 500;
        border: none;
        cursor: pointer;

        &:hover {
            background-color: $lively-blue-400 !important;
        }
    }
}
