@import 'common/Common';

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .spin-container {
        width: 100%;
        padding-top: 50px;
        display: flex;
        justify-content: center;
    }

    .content-container {
        width: 100%;

        .question {
            margin-bottom: 28px;
        }

        .image-container {
            display: flex;
            justify-content: center;
            margin-bottom: 28px;
        }
    }
}
