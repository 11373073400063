@import 'common/Common.scss';

.container {
    min-height: calc(100vh - #{$navigation-bar-height} + 2px);
    display: flex;
    justify-content: center;

    @media (min-width: 600px) {
        .content-container {
            width: 60%;
        }
    }
    .content-container {
        .title {
            display: flex;
            justify-content: center;
            text-align: center;
            margin: 20px 0;
        }

        .content {
            font-family: Roboto, sans-serif;
            text-align: center;
            font-size: 20px;
            padding: 0 20px;
            margin-bottom: 20px;

            > ol {
                margin-top: 16px;
            }
        }

        .buttons-container {
            display: flex;
            justify-content: center;

            button {
                font-family: Roboto, sans-serif;
                font-weight: 500;
                color: white;
                background-color: $lively-blue-500;
                border: none;
                border-radius: 4px;
                height: 40px;
                margin-right: 10px;

                &:hover {
                    background-color: $lively-blue-400;
                }
            }
        }
    }
}
