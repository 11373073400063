@import 'common/Common.scss';

@mixin section-title {
    @include public-header-2;
    padding: 0 20px;
    width: 100%;
    text-align: center;
}

.container {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 48px;

    .section-title {
        @include public-section-title;
    }

    .content {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
        gap: 24px;
        margin-bottom: 48px;

        .text-container {
            width: 650px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .title {
                @include public-subtitle-1;
                font-size: 30px;
                line-height: normal;
                margin-bottom: 24px;
            }

            .text {
                @include public-body-text-2;
            }

            @media screen and (max-width: 768px) {
                width: 100%;
                padding: 0 36px;
            }
        }

        .image-container {
            display: flex;
            justify-content: center;
            width: 700px;

            @media screen and (max-width: 768px) {
                width: 100%;
            }
        }
    }

    .reverse {
        flex-direction: row-reverse;
    }

    @media screen and (max-width: 1080px) {
        .content {
            flex-direction: column;
            align-items: center;
        }
    }
}
