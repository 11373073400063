@import 'common/Common';

.dropdown-menu {
    box-sizing: border-box;

    :global {
        .ant-dropdown-menu {
            margin-top: 4px;
            width: 160px;
            padding: 12px 30px 12px 24px;
            border: 1px solid #eaebec;
            border-radius: 4px;
            box-shadow: 0 4px 4px #eaebec;
            display: flex;
            flex-direction: column;
        }

        .ant-dropdown-menu-item {
            margin: 4px 0;
            padding: 0;
            width: 100%;

            &-active {
                background-color: white !important;

                a {
                    background-color: white;
                    color: $lively-blue-500 !important;
                }
            }
        }
    }
}

.avatar-container {
    height: 32px;
    min-width: 32px;
    border-radius: 50%;
    border: 2px solid #2c6ce8;

    margin-left: 88px;
    cursor: pointer;
    overflow: hidden;

    .initial-placeholder {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
