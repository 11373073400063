.container {
    display: flex;
    justify-content: center;
    width: 100%;

    position: relative;
    padding-top: 56.25%; // Percentage ratio for 16:9
    margin-bottom: 24px;

    .react-player {
        position: absolute;
        top: 0;
        left: 0;
    }
}
