@import 'common/Common';

.container {
    padding: 20px;

    .title {
        text-align: center;
    }

    .body {
        text-align: justify;

        .highlight {
            color: $lively-blue-400;
        }
    }
}
