@import 'common/Common';

.avatar-container {
    height: 32px;
    min-width: 32px;
    border-radius: 50%;
    border: 2px solid #2c6ce8;

    .initial-placeholder {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
