@import 'common/Common';

.container {
    font-family: Roboto, sans-serif;
    background-color: $dark;
    color: white;
    padding-top: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media only screen and (max-width: 768px) {
        padding: 16px 48px 0 48px;
    }

    @media only screen and (max-width: 480px) {
        padding: 16px 32px 0 32px;
    }

    :global {
        .ant-layout-footer {
            padding: 0;
            background-color: $dark;
            width: 100%;
            display: flex;
            flex-direction: column;

            .anticon {
                width: 24px;
            }
        }
    }

    .content-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        margin-bottom: 32px;
        gap: 24px 72px;
        font-weight: 500;
        font-size: 16px;
        height: 100%;

        @media only screen and (max-width: 768px) {
            flex-direction: column;
        }

        .social-media-container {
            display: flex;
            flex-direction: column;
            height: 184px;

            @media only screen and (max-width: 768px) {
                height: min-content;
            }

            .logo-container {
                display: flex;
                justify-content: center;

                .logo {
                    object-fit: cover;
                    height: 80px;
                    width: 200px;
                }
            }

            .icons-container {
                display: flex;
                justify-content: center;
                gap: 18px;

                .icon {
                    svg {
                        color: white;
                        width: 28px;
                        height: 28px;
                    }
                }
            }
        }

        .links-container {
            padding-top: 26px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            gap: 48px 80px;

            .column {
                display: flex;
                flex-direction: column;
                gap: 12px;

                h3 {
                    color: white;
                    margin-bottom: 14px;
                }

                a {
                    color: white;
                }
            }
        }
    }

    .copyright-notice {
        color: white;
        width: 100%;
        margin-bottom: 24px;
        text-align: center;
    }
}
