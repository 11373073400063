.container {
    padding: 25px 0;

    .title {
        font-size: 36px;
        font-weight: 500;
        text-align: center;
        margin-bottom: 25px;
    }

    .conversation-parent-container {
        height: 100%;
        padding: 25px 75px;

        .conversation-container {
            display: flex;
            justify-content: center;
            max-height: 750px;
            overflow-x: hidden;
            overflow-y: auto;
        }

        .card-container {
            padding: 25px 45px 25px 25px;

            .card-text {
                padding-top: 15px;
                text-align: center;
                font-size: 18px;
                font-weight: 500;
                margin-bottom: 25px;
            }

            .card-cta {
                display: flex;
                justify-content: center;
                padding-bottom: 15px;
            }
        }
    }

    .button-group {
        display: flex;
        justify-content: center;
        margin-top: 25px;
    }
}

.disclaimer {
    padding: 25px;

    .disclaimer-text {
        .header {
            font-size: 24px;
            font-weight: 500;
            margin-bottom: 15px;
            text-align: center;
        }

        .subheader {
            text-align: center;
            margin-bottom: 20px;
        }

        .ordered-list {
            text-align: left;
            margin-bottom: 45px;

            li {
                margin-bottom: 15px;

                .li-title {
                    font-weight: 500;
                }
            }
        }
    }

    .disclaimer-cta {
        display: flex;
        justify-content: center;
    }
}
