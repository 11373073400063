@import 'common/Common.scss';

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap-reverse;
    width: 100%;
    margin-top: 48px;
    margin-bottom: 72px;
    gap: 60px;

    .pie-chart-container {
        position: relative;
        flex-basis: min-content;
        min-width: 300px;

        .text {
            @include public-body-text-1;
            text-align: center;
            position: absolute;
            top: 360px;
        }
    }

    .content-container {
        @include public-body-text-1;
        width: 750px;
        margin-top: 48px;

        h1 {
            font-family: Poppins, sans-serif;
            color: $dark;
            font-size: 60px;
            font-weight: 500;
            line-height: 72px;
        }

        .subtitle {
            @include public-body-text-1;
            margin-bottom: 48px;
        }

        .cta-container {
            width: min-content;

            .emphasis {
                font-size: 16px;
                font-weight: 700;
                text-align: center;
                margin: 4px 0;
            }
        }

        @media screen and (max-width: 768px) {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            padding: 0 24px;
            margin-top: 24px;
        }
    }
}
