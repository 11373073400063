@import 'common/Common';

.container {
    display: flex;
    padding: 16px;
    justify-content: space-between;

    > textarea {
        border-radius: 8px;
        margin-right: 16px;
        flex-grow: 1;
        // this hides the scrollbar but still allow scrolling
        -ms-overflow-style: none; /* Internet Explorer 10+ */
        scrollbar-width: none; /* Firefox */

        &::-webkit-scrollbar {
            display: none; /* Safari and Chrome */
        }
    }

    .button-container {
        display: flex;
        justify-content: center;
        margin-top: 16px;

        .submit-button {
            background-color: $lively-blue-500;
            color: white;
            border-radius: 4px;
            font-weight: 500;
            border: none;

            &:hover {
                background-color: $lively-blue-400;
            }

            &:disabled {
                background-color: #f5f5f5;
                color: $grey-200;
            }
        }
    }
}
