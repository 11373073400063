@import 'common/Common';

.container {
    background-color: $arc-bg-grey;
    min-height: 100vh;

    .title {
        padding-top: 200px;
        text-align: center;
    }

    .sub-title {
        margin-top: 25px;
        text-align: center;
    }

    .cta {
        margin-top: 150px;
        display: flex;
        justify-content: center;
    }
}
