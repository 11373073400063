@import 'common/Common';

.container {
    height: 88px;
    width: 100%;
    margin-bottom: 24px;
    // .ant-list-item sets border-bottom to none by default, !important exists to override it
    border: 1px solid $grey-100 !important;
    border-radius: 8px;

    .text {
        padding: 0 40px;
        color: $dark;
        margin: 0;
    }

    &:hover {
        cursor: pointer;
        box-shadow: 0 3px 25px 0 #cccccc40;
    }
}

.selected {
    background-color: $lively-blue-100;
    // .ant-list-item sets border-bottom to none by default, !important exists to override it
    border: 1px solid $lively-blue-500 !important;

    .text {
        color: $lively-blue-500;
    }
}

.disabled {
    background-color: $grey-100;
    // .ant-list-item sets border-bottom to none by default, !important exists to override it
    border: 1px solid $grey-200 !important;

    .text {
        color: $grey-200;
    }

    &:hover {
        cursor: not-allowed;
    }
}
