.videos-card {
    width: 350px;
    border-radius: 5px;
    border: 1px solid #eaf7e6;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    background-color: #eaf7e6;
    padding: 24px;
    margin: 25px;

    &:hover {
        cursor: pointer;
        background-color: #e0f3d9;
    }

    &.explore {
        background-color: rgba(115, 153, 198, 0.5);
        border-color: 0;
    }

    &.pending {
        background-color: #f5f5f5;
        border-color: #f5f5f5;

        &:hover {
            cursor: default;
        }
    }

    .question {
        text-align: center;
        font-weight: 500;
        font-size: 20px;
        margin-bottom: 20px;
    }

    .thumbnail-container {
        margin-bottom: 20px;
    }

    .tags-container {
        .tags {
            margin-bottom: 5px;
        }
    }
}
