@import 'common/Common';

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;

    .spin-container {
        width: 100%;
        padding-top: 50px;
        display: flex;
        justify-content: center;
    }

    .content-container {
        width: 50%;

        .title {
            margin: 64px 0 40px 0;
            color: $dark;
        }
        .subtitle-container {
            margin-bottom: 24px;

            .question-number-container {
                display: flex;
                align-items: center;
                margin-bottom: 8px;

                .question-number {
                    width: 200px;
                }
            }

            .subtitle {
                color: $grey-400;
            }
        }

        .show-answer-button-container {
            display: flex;
            justify-content: center;
            margin-top: 24px;

            // translateY is applied to even out the position difference when it is toggled
            // as setting justify-content with display: flex doesn't work
            > button {
                width: 100%;
                height: 40px;
                background-color: $lively-blue-500;
                color: white;
                border: none;
                border-radius: 4px;

                &:hover {
                    background-color: $lively-blue-400;
                }

                svg {
                    font-size: 16px;
                    transform: translateY(2px);
                }
            }

            .reverse-arrow {
                transform: rotateZ(180deg) translateY(-1px);
            }
        }

        .pdf-container {
            height: 480px;
            overflow-y: scroll;
        }

        .buttons-footer {
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-top: 64px;
            padding: 40px 0;
            border-top: 1px solid $grey-100;

            > button {
                height: 40px;
                border-radius: 4px;
            }

            .previous {
                color: $lively-blue-500;
                border-color: $lively-blue-500;

                &:hover {
                    color: $lively-blue-400;
                    border-color: $lively-blue-400;
                }

                &:disabled {
                    color: $grey-200;
                    border-color: $grey-200;
                }
            }

            .next {
                background-color: $lively-blue-500;
                border: none;

                &:hover {
                    background-color: $lively-blue-400;
                }

                &:disabled {
                    color: $grey-200;
                    border: 1px solid $grey-200;
                    background-color: #f5f5f5;
                }
            }
        }
    }
}

.download-container {
    position: fixed;
    z-index: 2;
    height: 100vh;
    width: 100vw;
    pointer-events: none;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.4);

    .download-content {
        position: fixed;
        z-index: 20;
        top: 250px;
        left: calc(50vw - 200px);

        background-color: white;
        border: 1px solid black;
        border-radius: 5px;

        height: 300px;
        width: 400px;

        padding: 45px;
        text-align: center;

        .spin {
            margin-top: 60px;
            display: flex;
            justify-content: center;
        }
    }
}
