@import 'common/Common';

.container {
    height: 100%;
    width: 894px;
    margin-bottom: 24px;
    border: 1px solid #cccccc40;
    padding: 40px;

    .title-container {
        display: flex;
        justify-content: space-between;
    }

    .image-container {
        display: flex;
        justify-content: center;
        margin: 40px 0;
    }

    .answers-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
