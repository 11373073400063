@import 'common/Common';

.container {
    display: flex;
    justify-content: space-between;
    user-select: none;
    align-items: center;

    .text {
        margin: 0 16px;
        color: $dark;
    }

    .disabled {
        svg {
            fill: $grey-200;

            &:hover {
                fill: $grey-200;
                cursor: default;
            }
        }
    }

    svg {
        transform: scale(1.1);
        fill: $lively-blue-500;

        &:hover {
            fill: $lively-blue-400;
        }
    }
}
