@import 'common/Common';

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 894px;
    margin: 0 auto;

    .spin-container {
        width: 100%;
        padding-top: 50px;
        display: flex;
        justify-content: center;
    }

    .content-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .title {
            width: 100%;
            margin: 64px 0 8px 0;
            color: $dark;
        }

        .subtitle {
            width: 100%;
            color: $grey-400;
            margin-bottom: 48px;
        }

        .score-tag-container {
            display: flex;
            justify-content: flex-end;

            .score-tag {
                background-color: $lively-blue-500;
                color: white;
                width: 254px;
                height: 64px;
                border-radius: 8px 8px 0 0;

                font-size: 32px;
                font-weight: 600;

                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        .quiz-card-container {
            height: 1200px;
            overflow-y: scroll;
        }

        .buttons-footer {
            display: flex;
            justify-content: center;
            width: 100%;
            margin-top: 64px;
            padding: 40px 0;
            border-top: 1px solid $grey-100;

            > button {
                height: 40px;
                border-radius: 4px;
                margin: 12px;
            }

            .try-again-button {
                color: $lively-blue-500;
                border-color: $lively-blue-500;

                &:hover {
                    color: $lively-blue-400;
                    border-color: $lively-blue-400;
                }

                &:disabled {
                    color: $grey-200;
                    border-color: $grey-200;
                }
            }

            .back-to-quizzes-button {
                background-color: $lively-blue-500;
                border: none;
                color: white;

                &:hover {
                    background-color: $lively-blue-400;
                    color: white;
                }

                &:disabled {
                    color: $grey-200;
                    border: 1px solid $grey-200;
                    background-color: #f5f5f5;
                }
            }
        }
    }
}
