@import 'common/Common.scss';

.container {
    :global {
        .ant-modal-content {
            border-radius: 8px;
        }
    }

    .body {
        display: flex;
        flex-direction: column;
        align-items: center;

        .title {
            width: 100%;
            text-align: center;
            margin-bottom: 16px;
        }

        .video-container {
            padding: 0 24px;
            width: 100%;
            height: 100%;
        }
    }
}
