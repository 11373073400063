@import 'common/Common';

.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 10px;

    .message {
        padding: 12px 16px;
        max-width: 250px;
        word-wrap: break-word;
    }

    .timestamp {
        color: $grey-500;
        font-size: 12px;
        margin: 0 10px;
    }
}

.client {
    display: flex;
    align-items: flex-end;

    .message {
        background-color: $lively-blue-100;
        border-radius: 16px 0 16px 16px;
    }
}

.admin {
    display: flex;

    .message {
        background-color: white;
        border: 1px solid $grey-200;
        border-radius: 0 16px 16px 16px;
    }
}
