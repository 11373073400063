@import 'common/Common';

.container {
    display: flex;
    justify-content: center;
    padding: 0 80px;
    width: 100%;

    .content-container {
        display: flex;
        flex-direction: column;
        align-items: center;

        .title {
            display: flex;
            text-align: center;
            padding: 0 48px;
            justify-content: center;
            margin-bottom: 96px;
        }

        .video-container {
            display: flex;
            justify-content: center;
            margin: 24px 0;
            width: 100%;
            height: 100%;
        }
    }
}

.loading-container {
    padding-top: 64px;
    display: flex;
    justify-content: center;
}
